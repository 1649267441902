import * as React from 'react'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@2/components/ui/select'
import { Period, display12HourValue, setDateByType } from './time-picker-utils'
import { Selector } from '../Selector'

export interface PeriodSelectorProps {
    period: Period
    setPeriod: (m: Period) => void
    date: Date | undefined
    setDate: (date: Date | undefined) => void
    onRightFocus?: () => void
    onLeftFocus?: () => void
    disabled?: boolean | undefined
}

export const TimePeriodSelect = React.forwardRef<
    HTMLButtonElement,
    PeriodSelectorProps
>(
    (
        {
            period,
            setPeriod,
            date,
            setDate,
            onLeftFocus,
            onRightFocus,
            disabled,
        },
        ref
    ) => {
        const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'ArrowRight') onRightFocus?.()
            if (e.key === 'ArrowLeft') onLeftFocus?.()
        }

        const handleValueChange = (value: Period) => {
            setPeriod(value)

            /**
             * trigger an update whenever the user switches between AM and PM;
             * otherwise user must manually change the hour each time
             */
            if (date) {
                const tempDate = new Date(date)
                const hours = display12HourValue(date.getHours())
                setDate(
                    setDateByType(
                        tempDate,
                        hours.toString(),
                        '12hours',
                        value === 'AM' ? 'AM' : 'PM'
                    )
                )
            }
        }

        return (
            <div className="flex h-full items-center">
                <Selector
                    value={period}
                    options={['AM', 'PM']}
                    onChange={(value: Period) => handleValueChange(value)}
                    disabled={disabled}
                    variant="secondary"
                />
            </div>
        )
    }
)

TimePeriodSelect.displayName = 'TimePeriodSelect'
