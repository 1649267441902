import { createFileRoute } from '@tanstack/react-router'
import StaffRoleListPageHeader from '../../Pages/StaffRoleListPage/StaffRoleListPageHeader'
import StaffRoleListPage from '../../Pages/StaffRoleListPage/StaffRoleListPage'
import PageBody from '../../Pages/Layout/PageBody'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'

export const Route = createFileRoute('/_private/staff-roles/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'staffRoleList', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    pendingComponent: LoadingSpinner,
    loader: async () => await loader(),
    component: Wrapper,
})

const loader = async () => {
    const collections = [
        {
            collection: 'reports',
            fields: [
                'name',
                'type',
                'columns',
                'filters',
                'groupBy',
                'sortBy',
                'options',
            ],
        },
    ].filter(Boolean)

    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return <StaffRoleListPage />
    }
    return (
        <>
            <StaffRoleListPageHeader />
            <PageBody>
                <StaffRoleListPage />
            </PageBody>
        </>
    )
}
