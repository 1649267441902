import { DateFormatter } from '@internationalized/date'
import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'

export const FormatTime = (
    value,
    options = { hour: '2-digit', minute: '2-digit' }
) => {
    const formatter = new DateFormatter(
        isoLangCodes.findCountryLocales(
            constantCase(SessionStore?.organisationCountry || 'us')
        )[0],
        options
    )
    return formatter.format(value)
}
