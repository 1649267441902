import { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Column, Row, Table } from '@tanstack/react-table'
import * as cuid from 'cuid'
import * as React from 'react'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
import download from 'downloadjs'
import Papa from 'papaparse'
import Formatter from '@/Components/Formatter'
import { trpc } from '@/system/trpc'
import { toast } from '@vendor/sonner'
import SessionStore from '@/State/SessionStore'
import { useNavigate } from '@tanstack/react-router'
import {
    csvFormatter,
    ExportColumn,
    OrganisationReport,
    sortColumnsAsInStore,
} from '@/version2/types'
import { DataTableActions } from '@/version2/components/data-table/advanced/data-table-action'
import { useTimeReportStore } from './time-report-store'
import { useGlobalCache } from '@/version2/cache'
import { useLayoutStore } from '@/version2/layout/layout-store'

interface TimeReportActionsProps<TData>
    extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
    exportColumns?: ExportColumn[]
}

export function TimeReportActions<TData>({
    table,
    exportColumns = [],
}: TimeReportActionsProps<TData>) {
    const { updateCache, deleteItem, cache } = useGlobalCache((state) => state)
    const { setPageTitle } = useLayoutStore(state => state)
    const [dialogContent, setDialogContent] = useState<string | null>(null)
    const [nameReport, setNameReport] = useState<string | null>(null)
    const { organisationReport, setOrganisationReport } = useTimeReportStore(
        (state) => state
    )
    const checkDisableButton =
        !organisationReport?.id &&
        !SessionStore.organisation?.defaultTimeReportId

    const handleOpenDialog = (content: string) => {
        setNameReport(null)
        setDialogContent(content)
    }

    const handleSaveReport = async (payload: Partial<OrganisationReport>) => {
        return toast.promise(trpc.timeReport.saveReport.mutate(payload), {
            loading: 'Saving changes...',
            success: (data) => {
                setNameReport(null)
                setOrganisationReport({
                    ...organisationReport,
                    ...data,
                })
                return 'All saved'
            },
            error: (errorToast) => {
                console.log(errorToast)
                return (
                    <div className="flex items-center justify-between w-full">
                        <span>Report has not been updated</span>
                        <Button
                            onClick={() => {
                                toast.dismiss()
                                handleSaveReport(payload)
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                )
            },
        })
    }

    const handleSetDefault = async () => {
        toast.promise(
            trpc.timeReport.setDefaultReport.mutate({
                id: organisationReport.id,
            }),
            {
                loading: 'Saving changes...',
                success: 'All saved',
                error: (errorToast) => {
                    return (
                        <div className="flex items-center justify-between w-full">
                            <span>Report has not been set default</span>
                            <Button
                                onClick={() => {
                                    toast.dismiss()
                                    handleSetDefault()
                                }}
                            >
                                Retry
                            </Button>
                        </div>
                    )
                },
            }
        )
    }

    const handleDialogSubmit = async (dialogContent: string) => {
        switch (dialogContent) {
            case 'Save as':
                const newId = cuid();
                const payloadSaveAs = {
                    id: newId,
                    name: nameReport,
                    organisationId: organisationReport.organisationId,
                    filters: organisationReport.filters,
                    groupBy: organisationReport.groupBy,
                    type: 'timesheet',
                    columns: organisationReport.columns,
                    options: organisationReport.options,
                }
                await handleSaveReport(payloadSaveAs)
                updateCache('organisationReports', [...cache.organisationReports, { id: newId, name: nameReport, type: 'timesheet' }])
                break
            case 'Save':
                const payloadSave = {
                    id: organisationReport.id,
                    organisationId: organisationReport.organisationId,
                    groupBy: organisationReport.groupBy,
                    filters: organisationReport.filters,
                    columns: organisationReport.columns,
                    options: organisationReport.options,
                }
                await handleSaveReport(payloadSave)
                break
            case 'Rename Report':
                if (nameReport !== organisationReport.name) {
                    const payloadRename = {
                        id: organisationReport.id,
                        organisationId: organisationReport.organisationId,
                        name: nameReport,
                    }
                    await handleSaveReport(payloadRename)
                    updateCache('organisationReports', [...cache.organisationReports.filter(item => item.id !== organisationReport.id), { id: organisationReport.id, name: nameReport, type: 'timesheet' }])
                    setPageTitle(nameReport)
                }
                break
            case 'Delete Report':
                const payloadDelete = {
                    id: organisationReport.id,
                    organisationId: organisationReport.organisationId,
                    deletedAt: new Date(),
                }
                await handleSaveReport(payloadDelete)
                deleteItem('organisationReports', organisationReport.id)
                break
            default:
                break
        }
        setDialogContent(null)
    }

    const handleExportToCSV = (type: string) => {
        const level = organisationReport.groupBy.indexOf(type)
        const data = table
            .getExpandedRowModel()
            .flatRows.filter((row) =>
                level >= 0
                    ? row.depth == level + 1
                    : row.depth == organisationReport.groupBy.length
            )
        const listColumnsExport = [];
        listColumnsExport.push(...organisationReport.groupBy, ...organisationReport.columns.filter(column => !organisationReport.groupBy.includes(column)));
        const columnsCSV = table
            .getAllColumns()
            .filter((c) => !['expand', 'select', 'label'].includes(c.id) && listColumnsExport.includes(c.id)).sort(sortColumnsAsInStore(organisationReport.groupBy))
            const csv = []
            csv.push(columnsCSV.map((c) => c?.columnDef?.meta?.title))
        csv.push(
            ...data.map((r) =>
                columnsCSV.map((c) => {
                    return csvFormatter(r, c)
                })
            )
        )

        download(Papa.unparse(csv), `Default timesheet.csv`, 'text/csv')
    }

    return (
        <>
            <DataTableActions
                handleExportToCSV={handleExportToCSV}
                handleDialogSubmit={handleDialogSubmit}
                handleOpenDialog={handleOpenDialog}
                handleSetDefault={handleSetDefault}
                dialogContent={dialogContent}
                setDialogContent={setDialogContent}
                setNameReport={setNameReport}
                nameReport={nameReport}
                organisationReport={organisationReport}
                checkDisableButton={checkDisableButton}
                exportColumns={exportColumns}
            />
        </>
    )
}
