import { useState, useMemo } from 'react'
import { Check, ChevronsUpDown, X } from 'lucide-react'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@2/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'
import { Button } from '@2/components/ui/button'
import { cn } from '@2/lib/utils'
import { camelCaseToSentence } from '@/utils'
import { useGlobalCache } from '@2/cache'
import { getLabel } from '@2/utils/get-label'

interface SelectorProps<T> {
    value?: T | null
    options: T[]
    onChange?: (value: T | null) => void
    getLabel?: (option: T) => string
    getValue?: (option: T) => string
    disableOption?: (option: T) => boolean
    groupBy?: (option: T) => string
    sort?: (a: T, b: T) => number
    placeholder?: string
    disabled?: boolean
    className?: string
    clearable?: boolean
    search?: boolean
}

const defaultGetLabel = (option: any) => option?.toString() ?? ''
const defaultGetValue = (option: any) => option?.toString() ?? ''

// Default sort function that uses getLabel for case-insensitive alphabetical sorting
const defaultSort =
    <T,>(getLabel: (option: T) => string) =>
    (a: T, b: T) => {
        return getLabel(a)
            .toLowerCase()
            .localeCompare(getLabel(b).toLowerCase())
    }

export function Selector<T>({
    value,
    options,
    onChange,
    getLabel = defaultGetLabel,
    getValue = defaultGetValue,
    disableOption,
    groupBy,
    sort = defaultSort(getLabel),
    placeholder = 'Select...',
    disabled = false,
    className,
    clearable = true,
    search = false,
    ...props
}: SelectorProps<T>) {
    const [open, setOpen] = useState(false)

    // Sort options if sort function is provided
    const sortedOptions = useMemo(() => {
        return [...options].sort(sort)
    }, [options, sort])

    // Group options if groupBy function is provided
    const groupedOptions = useMemo(() => {
        if (!groupBy) return null

        return Object.entries(
            sortedOptions.reduce(
                (acc, option) => {
                    const groupKey = groupBy(option)
                    if (!acc[groupKey]) acc[groupKey] = []
                    acc[groupKey].push(option)
                    return acc
                },
                {} as Record<string, T[]>
            )
        )
    }, [sortedOptions, groupBy])

    const handleClear = (e: React.MouseEvent) => {
        e.stopPropagation()
        onChange?.(null)
    }

    return (
        <Popover open={open} onOpenChange={disabled ? undefined : setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={cn(
                        'w-full justify-between relative pr-8',
                        className
                    )}
                    disabled={disabled}
                >
                    <span className="truncate">
                        {value ? getLabel(value) : placeholder}
                    </span>
                    <div className="flex absolute right-2 top-1/2 -translate-y-1/2">
                        {clearable && value && (
                            <Button
                                variant="ghost"
                                onClick={handleClear}
                                className="h-auto p-0 hover:bg-transparent"
                                type="button"
                            >
                                <X className="h-4 w-4 text-muted-foreground hover:text-foreground" />
                            </Button>
                        )}
                        <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 ml-1" />
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="w-[500px] p-0"
                onWheel={(e) => e.stopPropagation()}
            >
                <Command>
                    {search && (
                        <CommandInput
                            placeholder={`Search ${placeholder.toLowerCase()}...`}
                        />
                    )}
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <div className="max-h-[300px] overflow-y-auto">
                            {groupedOptions ? (
                                groupedOptions.map(
                                    ([group, items], groupIndex) => (
                                        <CommandGroup
                                            key={group + groupIndex}
                                            heading={group}
                                        >
                                            {items.map((option) => (
                                                <CommandItem
                                                    key={getValue(option)}
                                                    value={getLabel(option)}
                                                    onSelect={() => {
                                                        onChange?.(option)
                                                        setOpen(false)
                                                    }}
                                                    disabled={disableOption?.(
                                                        option
                                                    )}
                                                >
                                                    <Check
                                                        className={cn(
                                                            'mr-2 h-4 w-4',
                                                            value &&
                                                                getValue(
                                                                    option
                                                                ) ===
                                                                    getValue(
                                                                        value
                                                                    )
                                                                ? 'opacity-100'
                                                                : 'opacity-0'
                                                        )}
                                                    />
                                                    {getLabel(option)}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    )
                                )
                            ) : (
                                <CommandGroup>
                                    {sortedOptions.map((option) => (
                                        <CommandItem
                                            key={getValue(option)}
                                            value={getLabel(option)}
                                            onSelect={() => {
                                                onChange?.(option)
                                                setOpen(false)
                                            }}
                                            disabled={disableOption?.(option)}
                                        >
                                            <Check
                                                className={cn(
                                                    'mr-2 h-4 w-4',
                                                    value &&
                                                        getValue(option) ===
                                                            getValue(value)
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                )}
                                            />
                                            {getLabel(option)}
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            )}
                        </div>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

interface ObjectSelectorProps {
    value?: any
    options?: any[]
    disabled?: boolean
    onChange?: (value: any | null) => void
    placeholder?: string
    className?: string
    clearable?: boolean
}

export const ProjectSelector: React.FC<ObjectSelectorProps> = ({
    value,
    options: providedOptions,
    onChange,
    placeholder = 'Select Project...',
    clearable = true,
    ...props
}) => {
    const { cache } = useGlobalCache((state) => state)
    const options = providedOptions || cache.projects

    return (
        <Selector
            value={value}
            onChange={onChange}
            options={options}
            getLabel={(project) =>
                project ? getLabel.projects(project) : placeholder
            }
            getValue={(project) => project?.id}
            sort={(a, b) => {
                // First, sort by status
                const statusOrder = [
                    'active',
                    'prospective',
                    'onHold',
                    'archived',
                ]
                const statusCompare =
                    statusOrder.indexOf(a.status || 'active') -
                    statusOrder.indexOf(b.status || 'active')

                // If status is the same, sort by name case-insensitive
                if (statusCompare === 0) {
                    return (a.name || '')
                        .toLowerCase()
                        .localeCompare((b.name || '').toLowerCase())
                }

                return statusCompare
            }}
            placeholder={placeholder}
            clearable={clearable}
            search={true}
            groupBy={
                options[0]?.status
                    ? (project) =>
                          camelCaseToSentence(
                              (project?.status || 'active') + 'Projects'
                          )
                    : undefined
            }
            {...props}
        />
    )
}

export const PhaseSelector: React.FC<ObjectSelectorProps> = ({
    value,
    options: providedOptions,
    onChange,
    placeholder = 'Select Phase...',
    clearable = true,
    ...props
}) => {
    const options = providedOptions || []

    return (
        <Selector
            value={value}
            onChange={onChange}
            options={options}
            getLabel={(phase) => (phase ? getLabel.phases(phase) : placeholder)}
            getValue={(phase) => phase?.id}
            sort={(a, b) => {
                // First, sort by status
                const statusOrder = [
                    'active',
                    'prospective',
                    'onHold',
                    'archived',
                ]
                const statusCompare =
                    statusOrder.indexOf(a.status || 'active') -
                    statusOrder.indexOf(b.status || 'active')

                // If status is the same, sort by name case-insensitive
                if (statusCompare === 0) {
                    return (a.name || '')
                        .toLowerCase()
                        .localeCompare((b.name || '').toLowerCase())
                }

                return statusCompare
            }}
            placeholder={placeholder}
            clearable={clearable}
            search={true}
            groupBy={
                options[0]?.status
                    ? (phase) =>
                          camelCaseToSentence(
                              (phase?.status || 'active') + 'Phases'
                          )
                    : undefined
            }
            {...props}
        />
    )
}

export const TaskSelector: React.FC<ObjectSelectorProps> = ({
    value,
    options: providedOptions,
    onChange,
    placeholder = 'Select Task...',
    clearable = true,
    ...props
}) => {
    const options = providedOptions || []

    return (
        <Selector
            value={value}
            onChange={onChange}
            options={options}
            getLabel={(task) => (task ? getLabel.tasks(task) : placeholder)}
            getValue={(task) => task?.id}
            sort={(a, b) => {
                return (a.name || '')
                    .toLowerCase()
                    .localeCompare((b.name || '').toLowerCase())
            }}
            placeholder={placeholder}
            clearable={clearable}
            search={true}
            {...props}
        />
    )
}
