import * as React from 'react'
import { useSearch } from '@tanstack/react-router'
import type { DataTableFilterField, DataTableFilterOption, OrganisationReport } from '@2/types'
import { PlusIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import type { Table } from '@tanstack/react-table'

import { cn } from '@2/lib/utils'
import { Button } from '@2/components/ui/button'
import { DataTableFilterCombobox } from '@2/components/data-table/advanced/data-table-filter-combobox'
import { DataTableGroupOptions, DataTableViewOptions } from '@2/components/data-table/data-table-view-options'

import { DataTableFilterItem } from '@2/components/data-table/advanced/data-table-filter-item'
import { DataTableMultiFilter } from '@2/components/data-table/advanced/data-table-multi-filter'

import { Input } from '@2/components/ui/input'
import { DateRangePicker } from '@2/components/date-range-picker'
import { trpc } from '@/system/trpc'
import { sub } from 'date-fns'
import { formatDateISOString } from '@/version2/utils/format'

interface DataTableAdvancedToolbarProps<TData>
    extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
    filterFields?: DataTableFilterField<TData>[]
    isAlertChangeColumns: boolean
    setIsAlertChangeColumns: React.Dispatch<React.SetStateAction<boolean>>
    setOrganisationReport: (newOrganisationReport: OrganisationReport) => void
    setReportData: (newReportData: any[] | []) => void,
    setIsLoadingReportData: (isLoading: boolean) => void,
    organisationReport: OrganisationReport,
    fetchReportData: (data: any) => Promise<any[]>
    setColumnsStore: (newColumns: string[]) => void
    setIsHideColumn: (isHideColumn: boolean) => void
    isHideColumn: boolean
    isHaveGroup?: boolean
    showApplyToPhase?: boolean
    groupByColumns?: string[]
    fixedColumns?: string[]
    showSearchFieldHeader?: boolean
}

export function DataTableAdvancedToolbar<TData>({
    table,
    filterFields = [],
    isAlertChangeColumns,
    setIsAlertChangeColumns,
    setOrganisationReport,
    setReportData,
    setIsLoadingReportData,
    fetchReportData,
    organisationReport,
    setColumnsStore,
    setIsHideColumn,
    isHideColumn,
    isHaveGroup = false,
    showApplyToPhase = true,
    children,
    className,
    groupByColumns = [],
    fixedColumns = [],
    showSearchFieldHeader = true,
    ...props
}: DataTableAdvancedToolbarProps<TData>) {
    const search = useSearch({ strict: false })
    const dataTableViewOptionsRef = React.useRef<{
        updatePreviousVisibleColumns: () => void
    } | null>(null)

    const reportFilters = organisationReport.filters

    const columns = organisationReport.columns

    const [dateRange, setDateRange] = React.useState<Date[]>(() => {
        const now = new Date()
        const fiftyYearsAgo = sub(now, { years: 50 })

        return organisationReport.dateRange
            .map((date) => {
                if (typeof date === 'number' && date === -Infinity) {
                    return fiftyYearsAgo
                }
                if (typeof date === 'number' && date === Infinity) {
                    return now
                }
                return date ? new Date(date) : now
            })
            .flat()
    })

    const options = React.useMemo<DataTableFilterOption<TData>[]>(() => {
        return filterFields.map((field) => {
            return {
                id: crypto.randomUUID(),
                label: field.label,
                value: field.value,
                options: field.options ?? [],
                typeFilter: field.typeFilter,
                groupBy: field.groupBy,
                sortGroups: field.sortGroups,
                filterValues: null,
                isApplyToPhases: false,
            }
        })
    }, [filterFields])

    const initialSelectedOptions = React.useMemo(() => {
        return reportFilters
            .map((filter) => {
                const findOption = options.find(
                    (option) => option.value === filter.column
                )
                return {
                    ...findOption,
                    filterValues: filter.value,
                    filterOperator: filter.operator,
                    isApplyToPhases: filter.group === 'phase',
                }
            })
            .filter(Boolean)
    }, [options, reportFilters])

    const [selectedOptions, setSelectedOptions] = React.useState<
        DataTableFilterOption<TData>[]
    >(initialSelectedOptions)

    const [openFilterBuilder, setOpenFilterBuilder] = React.useState(
        initialSelectedOptions.length > 0 || false
    )
    const [openCombobox, setOpenCombobox] = React.useState(false)

    const [isAlertChangeFilter, setIsAlertChangeFilter] = React.useState(false)
    const [isDateRangeChanged, setIsDateRangeChanged] = React.useState(false)
    const [isUpdatingReport, setIsUpdatingReport] = React.useState(false)
    const [updateError, setUpdateError] = React.useState(false)

    const prevSelectedOptions =
        React.useRef<DataTableFilterOption<TData>[]>(selectedOptions)
    const prevDateRange = React.useRef<Date[]>(dateRange)

    React.useEffect(() => {
        const areDateRangesEqual = (
            range1: Date[],
            range2: Date[]
        ): boolean => {
            if (
                formatDateISOString(range1[0]) ===
                formatDateISOString(range2[0]) &&
                formatDateISOString(range1[1]) ===
                formatDateISOString(range2[1])
            )
                return true
        }

        if (!areDateRangesEqual(dateRange, prevDateRange.current)) {
            setIsDateRangeChanged(true)
        } else setIsDateRangeChanged(false)
    }, [dateRange])

    React.useEffect(() => {
        const hasChanged =
            JSON.stringify(prevSelectedOptions.current) !==
            JSON.stringify(selectedOptions)
        if (hasChanged) {
            setIsAlertChangeFilter(true)
        } else setIsAlertChangeFilter(false)
    }, [selectedOptions])

    function onFilterComboboxItemSelect() {
        setOpenFilterBuilder(true)
        setOpenCombobox(true)
    }

    const handleApplyChanges = async () => {
        setIsUpdatingReport(true)
        prevSelectedOptions.current = selectedOptions
        dataTableViewOptionsRef.current?.updatePreviousVisibleColumns()
        prevDateRange.current = dateRange
        const filters = selectedOptions.map((selectedOption) => {
            return {
                column: selectedOption.value,
                operator:
                    selectedOption.filterOperator ||
                    (selectedOption.typeFilter === 'select' ? 'in' : '='),
                value: selectedOption.filterValues,
                group: selectedOption.isApplyToPhases ? 'phase' : 'project',
            }
        })

        const { id, options, sortBy, groupBy, ...restOfProjectReport } = organisationReport
        setOrganisationReport({
            ...organisationReport,
            filters,
            dateRange: dateRange.map((date) => formatDateISOString(date)),
        })
        
        const submitData = {
            ...restOfProjectReport,
            filters,
            columns,
            dateRange: dateRange.map((date) => formatDateISOString(date)),
            sortBy: sortBy?.length ? sortBy : [],
            groupBy: groupBy?.length ? groupBy : groupByColumns
        }

        try {
            setIsLoadingReportData(true)
            const filteredData = await fetchReportData(submitData)
            setIsLoadingReportData(false)
            setReportData(filteredData)
            setIsUpdatingReport(false)
            setIsAlertChangeColumns(false)
            setIsAlertChangeFilter(false)
            setIsDateRangeChanged(false)
            setUpdateError(false)
        } catch (err) {
            console.log(err)
            setIsLoadingReportData(false)
            setIsUpdatingReport(false)
            setUpdateError(true)
        }
    }

    return (
        <div
            className={cn(
                'flex w-full flex-col space-y-2.5 overflow-auto p-8  print:hidden',
                className
            )}
            {...props}
        >
            <div className="flex items-center justify-between gap-2">
                <div className="min-w-[300px]">
                    <DateRangePicker
                        onUpdate={(values) =>
                            setDateRange([values.range.from, values.range.to])
                        }
                        initialDateFrom={dateRange[0]}
                        initialDateTo={dateRange[1]}
                        align="start"
                        locale="en-GB"
                        showCompare={false}
                    />
                </div>
                <div className="flex items-center gap-2">
                    {
                        showSearchFieldHeader && (
                            <Input
                                key="name"
                                placeholder="Filter by project name"
                                value={
                                    (table
                                        .getColumn('name')
                                        ?.getFilterValue() as string) ?? ''
                                }
                                onChange={(event) =>
                                    table
                                        .getColumn('name')
                                        ?.setFilterValue(event.target.value)
                                }
                                className="h-8 w-40 lg:w-64"
                            />
                        )
                    }
                    {isHaveGroup && (
                        <DataTableGroupOptions
                            table={table}
                            setShowAlert={(value) => setIsAlertChangeColumns(value)}
                            ref={dataTableViewOptionsRef}
                            setOrganisationReport={setOrganisationReport}
                            organisationReport={organisationReport}
                        />
                    )}
                    <DataTableViewOptions
                        table={table}
                        setShowAlert={(value) => setIsAlertChangeColumns(value)}
                        ref={dataTableViewOptionsRef}
                        setColumnsStore={setColumnsStore}
                        setIsHideColumn={setIsHideColumn}
                        isHideColumn={isHideColumn}
                        setOrganisationReport={setOrganisationReport}
                        organisationReport={organisationReport}
                        fixedColumns={fixedColumns}
                    />
                    {children}
                </div>
            </div>
            <div
                className={cn(
                    'flex items-center gap-2 flex-wrap',
                )}
            >
                {selectedOptions
                    .filter((option) => !option.isMulti)
                    .map((selectedOption) => (
                        <DataTableFilterItem
                            key={String(selectedOption.value)}
                            table={table}
                            selectedOption={selectedOption}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            defaultOpen={openCombobox}
                            showApplyToPhase={showApplyToPhase}
                        />
                    ))}
                {selectedOptions.some((option) => option.isMulti) ? (
                    <DataTableMultiFilter
                        table={table}
                        allOptions={options}
                        options={selectedOptions.filter(
                            (option) => option.isMulti
                        )}
                        setSelectedOptions={setSelectedOptions}
                        defaultOpen={openCombobox}
                    />
                ) : null}

                <DataTableFilterCombobox
                    options={options}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    onSelect={onFilterComboboxItemSelect}
                >
                    <Button
                        variant="outline"
                        size="sm"
                        role="combobox"
                        className="h-7 rounded-full print:hidden"
                        onClick={() => setOpenCombobox(true)}
                    >
                        <PlusIcon
                            className="mr-2 size-4 opacity-50"
                            aria-hidden="true"
                        />
                        Add filter
                    </Button>
                </DataTableFilterCombobox>
            </div>
            {/* Alert here */}
            {(isAlertChangeColumns ||
                isAlertChangeFilter ||
                isDateRangeChanged) && (
                    <div
                        className={cn(
                            'flex items-center justify-between rounded-md px-4 py-2 border',
                            updateError ? 'border-red-500' : 'border-gray-300'
                        )}
                    >
                        <div className="flex items-center">
                            <InfoCircledIcon className="mr-2 size-4" />
                            <span>
                                {updateError
                                    ? 'There was an error updating the report'
                                    : 'Report setting have been modified'}
                            </span>
                        </div>
                        <Button
                            onClick={handleApplyChanges}
                            className={cn('bg-[#facc15] rounded-md px-4 py-1')}
                            disabled={isUpdatingReport}
                        >
                            {isUpdatingReport
                                ? 'Loading...'
                                : updateError
                                    ? 'Try again'
                                    : 'Apply changes below'}
                        </Button>
                    </div>
                )}
        </div>
    )
}
