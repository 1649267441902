import * as React from 'react'

import {
    type DataTableConfig,
} from '@/version2/config/data-table'

type FeatureFlagValue = DataTableConfig['featureFlags'][number]['value']

interface TimeReportTableContextProps {
    featureFlags: FeatureFlagValue[]
    setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlagValue[]>>
}

const TimeReportTableContext =
    React.createContext<TimeReportTableContextProps>({
        featureFlags: [],
        setFeatureFlags: () => {},
    })

export function useTimeReportTable() {
    const context = React.useContext(TimeReportTableContext)
    if (!context) {
        throw new Error(
            'useTimeReportTable must be used within a TimeReportTableProvider'
        )
    }
    return context
}

export function TimeReportTableProvider({
    children,
}: React.PropsWithChildren) {
    const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagValue[]>([
        'advancedFilter',
    ])

    return (
        <TimeReportTableContext.Provider
            value={{
                featureFlags,
                setFeatureFlags,
            }}
        >
            {children}
        </TimeReportTableContext.Provider>
    )
}
