import { Button } from '@2/components/ui/button'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@2/components/ui/tooltip'
import { cn } from '@2/lib/utils'

export const NavButton = ({
    icon: Icon,
    label,
    onClick,
    isExpanded,
    className,
    active,
    ...props
}) => (
    <TooltipProvider>
        <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
                <Button
                    size="sm"
                    variant="ghost"
                    className={cn(
                        'w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground',
                        !isExpanded && 'justify-center p-2',
                        active && 'bg-accent text-accent-foreground',
                        className
                    )}
                    onClick={onClick}
                    {...props}
                >
                    <Icon className={cn('h-4 w-4', isExpanded && 'mr-2')} />
                    {isExpanded && <span>{label}</span>}
                </Button>
            </TooltipTrigger>
            {!isExpanded && (
                <TooltipContent
                    side="right"
                    align="center"
                    className="flex items-center"
                >
                    {label}
                </TooltipContent>
            )}
        </Tooltip>
    </TooltipProvider>
)

export const SideNavButton = ({
    icon: Icon,
    label,
    onClick,
    active,
    ...props
}) => (
    <NavButton
        icon={Icon}
        label={label}
        onClick={onClick}
        isExpanded={true}
        className="mb-4"
        active={active}
        {...props}
    />
)
