import { useState } from 'react'
import { Dialog, DialogClose, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog'
import { DialogContent } from './ui/dialog'
import { Button } from './ui/button'
import { Selector } from './selector'

interface DataModal {
    isOpenDialog: boolean
    setIsOpenDialog: (isOpenDialog: boolean) => void
    style?: Record<string,string>,
    bodyStyle?: Record<string,string>,
    children: any,
    onSave: () => void,
    saveLabel: string,
    saveOptions?: any,
    saveButtons?: any,
    defaultSaveOption?: string,
    heading: string,
    isLoading: boolean,
    canSave: () => Boolean,
    styleSaveButton: Record<string,string>
}

export const Modal = ({
    isOpenDialog,
    setIsOpenDialog,
    style,
    bodyStyle,
    children,
    onSave,
    saveLabel,
    saveOptions,
    saveButtons,
    styleSaveButton,
    defaultSaveOption,
    heading,
    isLoading,
    canSave = () => true,
    ...props
}: DataModal) => {
    const [selectedSaveOption, setSelectedSaveOption] = useState(
        saveOptions?.length
            ? saveOptions.find((o) => o.id === defaultSaveOption) ||
            saveOptions[0]
            : null
    )
    console.log(styleSaveButton)
    return (
        <>
            <Dialog
                open={isOpenDialog}
                onOpenChange={() => setIsOpenDialog(false)}
            >
                <DialogContent className="sm:max-w-[630px]">
                    <DialogHeader className="border-b border-b-[#ccc]">
                        <DialogTitle>{heading}</DialogTitle>
                    </DialogHeader>
                    <div style={bodyStyle}>
                        {children}
                    </div>
                    <DialogFooter>
                        <DialogClose asChild>
                            <Button
                                type="button"
                                variant="secondary"
                            >
                                Close
                            </Button>
                        </DialogClose>
                        {saveButtons && saveButtons.map(({ label, onClick }) => (
                            <Button
                                className="btn btn-success"
                                key={label}
                                style={{ marginLeft: '1em' }}
                                onClick={() => {
                                    onClick()
                                    setIsOpenDialog(false)
                                }}
                            >
                                {label}
                            </Button>
                        ))}
                        {!!onSave && (
                            <Button
                                style={styleSaveButton}
                                disabled={!canSave() || isLoading}
                                onClick={onSave}
                            >
                                {saveLabel}
                            </Button>
                        )}
                        {saveOptions?.length ? (
                            <Selector
                                disableOption={() => !canSave()}
                                options={saveOptions}
                                getLabel={(o) => o.label}
                                onChange={setSelectedSaveOption}
                                className="max-w-max"
                            />
                        ) : null}
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}
