import { createFileRoute } from '@tanstack/react-router'
import TimesheetCalendarPage from '../../Pages/TimesheetCalendarPage/TimesheetCalendarPage'
import { CalendarSidebar } from '../../Components/Calendar/CalendarSidebar'
import PageBody from '../../Pages/Layout/PageBody'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { qf } from '../../Queries/queryFormatter'
import { endOfWeek, parse, startOfWeek } from 'date-fns'
import fetchData from '../../Queries/fetchData'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { useLayoutStore } from '@2/layout/layout-store'
import { usingNewLogin } from '../../State/Permissions/HasPermissions'
export const Route = createFileRoute('/_private/timesheets/calendar')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'timesheetCalendar', params))
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            layoutStore.setDefaults({
                pageTitle: 'Timesheet Calendar',
                subMenu: 'time',
            })
        }
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { date, staffId } }) => ({
        date,
        staffId,
    }),
    component: Wrapper,
})
const loader = async ({ date, staffId } = {}) => {
    date = date ? parse(date, 'yyyy-MM-dd', new Date()) : new Date()
    const collections = [
        {
            collection: 'timeEntries',
            fields: [
                'costCentreId',
                'projectId',
                'phaseId',
                'staffId',
                'taskId',
                'startMinutes',
                'numMinutes',
                'date',
                'notes',
                'isVariation',
                'isBillable',
                'isOvertime',
                'isLocked',
                'deletedAt',
                'flexi',
                'remote',
            ],
            filters: [
                `staffId == "${staffId || SessionStore.userId}"`,
                `date >= ${qf(startOfWeek(date, { weekStartsOn: 1 }))}`,
                `date <= ${qf(endOfWeek(date, { weekStartsOn: 1 }))}`,
                'numMinutes > 0',
            ],
        },
        {
            collection: 'phases',
            fields: [
                'jobNumber',
                'name',
                'projectId',
                'isRootPhase',
                'position',
                ['timeEntryCount', 'phaseTimeEntries.count'],
            ],
            subQueries: [
                {
                    label: 'phaseTimeEntries',
                    collection: 'timeEntries',
                    join: 'id == phaseTimeEntries.phaseId',
                    groupBy: ['phaseId'],
                    fields: [['count', 'count(id)']],
                    filters: [
                        `date >= ${qf(
                            startOfWeek(date, {
                                weekStartsOn: 1,
                            })
                        )}`,
                        `date <= ${qf(
                            endOfWeek(date, {
                                weekStartsOn: 1,
                            })
                        )}`,
                    ],
                },
            ],
            filters: ['phaseTimeEntries.count > 0'],
        },
        {
            collection: 'tasks',
            fields: [
                'name',
                'phaseId',
                'projectId',
                ['timeEntryCount', 'taskTimeEntries.count'],
            ],
            subQueries: [
                {
                    label: 'taskTimeEntries',
                    collection: 'timeEntries',
                    join: 'id == taskTimeEntries.taskId',
                    groupBy: ['taskId'],
                    fields: [['count', 'count(id)']],
                    filters: [
                        `date >= ${qf(
                            startOfWeek(date, {
                                weekStartsOn: 1,
                            })
                        )}`,
                        `date <= ${qf(
                            endOfWeek(date, {
                                weekStartsOn: 1,
                            })
                        )}`,
                    ],
                },
            ],
            filters: ['taskTimeEntries.count > 0'],
        },
        {
            collection: 'dailyAllocations',
            fields: [
                'costCentreId',
                'projectId',
                'phaseId',
                'staffId',
                'taskId',
                'startMinutes',
                'numMinutes',
                'date',
                'notes',
                'isVariation',
                'isBillable',
                'isOvertime',
                'isLocked',
                'deletedAt',
            ],
            filters: [
                `staffId == "${staffId || SessionStore.userId}"`,
                `date >= ${qf(startOfWeek(date, { weekStartsOn: 1 }))}`,
                `date <= ${qf(endOfWeek(date, { weekStartsOn: 1 }))}`,
                'numMinutes > 0',
            ],
        },
    ].filter(Boolean)

    return await Promise.all(collections.map(fetchData))
}
function Wrapper() {
    let { date, staffId } = Route.useSearch()
    date = date ? parse(date, 'yyyy-MM-dd', new Date()) : new Date()
    return (
        <>
            <PageBody>
                <TimesheetCalendarPage date={date} staffId={staffId} />
            </PageBody>

            <PageSidebar>
                <CalendarSidebar />
            </PageSidebar>
        </>
    )
}
