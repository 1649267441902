import { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { MarqueeButton } from '@2/components/ui/marquee-button'
import { ChevronDown, ChevronRight, Search } from 'lucide-react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Link, useNavigate } from '@tanstack/react-router'
import { SideNavButton } from './navigation-button'
import { Virtuoso } from 'react-virtuoso'

export const ExpandableList = ({
    title,
    items = [],
    isExpanded,
    onToggle,
    icon: Icon,
    rootRoute,
}) => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('')
    const [parent, enableAnimations] = useAutoAnimate()

    const filteredItems = items.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
    )
    if (items.length === 0 && rootRoute) {
        return (
            <SideNavButton
                icon={Icon}
                label={title}
                onClick={(e) => {
                    navigate({ to: rootRoute })
                }}
            />
        )
    }

    return (
        <div
            ref={parent}
            style={{
                height: isExpanded
                    ? `${Math.min(25, filteredItems.length) * 1.5 + 6}rem`
                    : 'auto',
            }}
            className={`${isExpanded ? 'flex-grow' : 'flex-shrink-0'} flex flex-col`}
        >
            <Button
                variant="ghost"
                size="sm"
                className="w-full justify-between mb-4 flex-none"
                onClick={onToggle}
            >
                <span className="font-semibold flex">
                    {Icon ? <Icon className="h-4 w-4 mr-2" /> : null}
                    {title}
                </span>
                {isExpanded ? (
                    <ChevronDown size={16} />
                ) : (
                    <ChevronRight size={16} />
                )}
            </Button>
            {isExpanded && (
                <div className="flex flex-col min-h-0 flex-1 mb-4">
                    <div className="relative -mt-2 mb-2">
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="pl-7 py-1 h-8 text-sm"
                        />
                        <Search className="absolute left-2 top-1/2 -translate-y-1/2 h-3 w-3 text-gray-400" />
                    </div>
                    <div className="flex-1">
                        <Virtuoso
                            style={{ height: '100%' }}
                            totalCount={filteredItems.length}
                            data={filteredItems}
                            className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent hover:scrollbar-thumb-gray-400"
                            itemContent={(index, item) => (
                                <MarqueeButton
                                    key={item.id}
                                    variant="ghost"
                                    size="xs"
                                    className="w-full justify-start mb-1"
                                    onClick={item.onClick}
                                >
                                    {item.route ? (
                                        <Link
                                            to={item.route}
                                            className="w-full justify-start"
                                        >
                                            {item.label}
                                        </Link>
                                    ) : (
                                        item.label
                                    )}
                                </MarqueeButton>
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
