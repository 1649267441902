import { createFileRoute, notFound } from '@tanstack/react-router'
import ErrorComponent from '../../Components/ErrorComponent'
import StaffPageHeader from '../../Pages/StaffPage/StaffPageHeader'
import StaffDetailsTab from '../../Pages/StaffPage/StaffDetailsTab'
import PageBody from '../../Pages/Layout/PageBody'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { idf } from '../../Queries/queryFormatter'
import fetchData from '../../Queries/fetchData'
import StaffPermissionsTab from '../../Pages/StaffPage/StaffPermissionsTab'
import StaffRatesTab from '../../Pages/StaffPage/StaffRatesTab'
import LoadingSpinner from '../../Components/LoadingSpinner'
import DataStore from '../../State/DataStore'
import { router } from '../../App'
import StaffCollection from '../../State/Collections/StaffCollection'
import { useLayoutStore } from '@2/layout/layout-store'
import { useGlobalCache } from '@2/cache'

const tabs = {
    details: {
        label: 'Details',
        id: 'details',
        page: (props) => <StaffDetailsTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'staff',
                    filters: [idf(id)],
                    fields: [
                        'firstName',
                        'lastName',
                        'email',
                        'roleId',
                        'isArchived',
                        'costCentreId',
                        'staffType',
                        'hasLogin',
                        'workosId',
                        'workosInviteId',
                    ],
                },
                {
                    collection: 'roles',
                    fields: ['name'],
                },
                {
                    collection: 'costCentres',
                    fields: ['name'],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, `staff-details`, params),
    },
    rates: {
        label: 'Rates',
        id: 'rates',
        page: (props) => <StaffRatesTab {...props} />,
        collections: (id) => {
            return [
                {
                    collection: 'staff',
                    filters: [idf(id)],
                    fields: [
                        'roleId',
                        'inheritPayRate',
                        'inheritOvertimeRate',
                        'inheritCostRate',
                        'inheritChargeOutRate',
                    ],
                    chain: [
                        {
                            collection: 'staffRates',
                            join: {
                                staff: 'id',
                                staffRates: 'staffId',
                            },
                            fields: [
                                'staffId',
                                'date',
                                'payRate',
                                'chargeOutRate',
                                'costRate',
                                'weeklyAvailability',
                                'overtimeRate',
                            ],
                        },
                        {
                            collection: 'roleRates',
                            join: {
                                staff: 'roleId',
                                roleRates: 'roleId',
                            },
                            fields: [
                                'roleId',
                                'date',
                                'payRate',
                                'chargeOutRate',
                                'costRate',
                                'overtimeRate',
                            ],
                        },
                        {
                            collection: 'roles',
                            join: {
                                staff: 'roleId',
                                roles: 'id',
                            },
                            fields: [
                                'id',
                                'name',
                                'avgPayRate',
                                'avgOvertimeRate',
                                'avgCostRate',
                                'avgChargeOutRate',
                            ],
                        },
                    ],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, `staff-rates`, params),
    },
    permissions: {
        label: 'Permissions',
        id: 'permissions',
        page: (props) => <StaffPermissionsTab {...props} />,
        collections: () => {
            return [
                {
                    collection: 'staff',
                    filters: ['isArchived == false'],
                    fields: ['permissions', 'permissionsId'],
                },
                {
                    collection: 'projects',

                    fields: ['jobNumber', 'name'],
                },
                {
                    collection: 'costCentres',

                    fields: ['name'],
                },
                {
                    collection: 'permissions',

                    fields: ['name', 'settings'],
                },
            ]
        },
        permissions: (params) =>
            canViewRoute(SessionStore.user, `staff-permissions`, params),
    },
}

export const Route = createFileRoute('/_private/staff/$id')({
    beforeLoad: ({ params, search }) => {
        const activeTab = search?.tab || 'details'
        if (
            !canViewRoute(SessionStore.user, 'staff', params) ||
            !canViewRoute(SessionStore.user, `staff-${activeTab}`, params)
        )
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            const cache = useGlobalCache.getState()
            const staff = cache.getItemById('staff', params.id)
            layoutStore.setDefaults({
                pageTitle: `${staff?.firstName} ${staff?.lastName}` || 'Staff',
                subMenu: 'resources',
                tabs: [
                    {
                        label: 'Details',
                        id: 'details',
                    },
                    {
                        label: 'Rates',
                        id: 'rates',
                    },
                    {
                        label: 'Permissions',
                        id: 'permissions',
                    },
                ],
            })
        }
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loaderDeps: ({ search: { tab } }) => ({
        tab,
    }),
    loader: async ({ params, location }) =>
        await loader(params.id, location.search),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
    onLeave: (match) => {
        const { id } = match.params
        const staff = StaffCollection.staffById[id]
        if (
            !staff?.createdAt &&
            match.pathname !== router.latestLocation.pathname
        ) {
            DataStore.destroyNewItems('staffId', id)
        }
    },
})

const loader = async (id, search) => {
    const { tab } = search
    const activeTab = tab || 'details'
    let collections = []
    collections = collections.concat(tabs[activeTab].collections(id))
    const data = await Promise.all(collections.filter(Boolean).map(fetchData))
    const staff = StaffCollection.staffById[id]
    if (!staff) {
        throw notFound()
    }
    return data
}

function Wrapper() {
    const { tab } = Route.useSearch()
    const params = Route.useParams()
    const activeTab = tab || 'details'
    if (migratedToV2()) {
        return <div>{tabs[activeTab].page(params)}</div>
    }
    return (
        <>
            <StaffPageHeader
                tabs={Object.values(tabs).filter((t) => t.permissions(params))}
                selectedTab={activeTab}
                {...params}
            />
            <PageBody>{tabs[activeTab].page(params)}</PageBody>
        </>
    )
}
