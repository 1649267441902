import { type ColumnDef } from '@tanstack/react-table'
import Formatter from '@/Components/Formatter'
import SessionStore from '@/State/SessionStore'
import {
    canViewProjectInvoices,
    canViewProjectFees,
    canViewProjectExpenseBudgets,
    canViewPrimaryContact,
    canViewRemote,
    canViewFlexi,
    canViewStaffPayRate,
    canViewStaffCostRate,
    canViewStaffChargeOutRate,
} from '@/State/Permissions/HasPermissions'
import ProgressBar from '@/Components/Widgets/ProgresBar2'
import CellComponent from '@/Components/CellComponent'
import { DataTableColumnHeader } from '@/version2/components/data-table/data-table-column-header'
import _ from 'lodash'
import { formatNumber0 } from '@/utils'
import { parse } from 'date-fns'
import { useGlobalCache } from '@/version2/cache'

const renderValue = (value, type, permissions, row) => {
    if (permissions !== undefined && !permissions(row)) {
        value = null
    }
    switch (type) {
        case 'currency':
            return CellComponent.currency({
                value,
                formattedValue: Formatter.currency(value),
            })
        case 'percent':
            return CellComponent.percent({
                value,
                formattedValue: Formatter.percent(value),
            })
        case 'date': {
            return CellComponent.date({
                value,
                formattedValue: Formatter.date(value ? new Date(value) : null),
            })
        }
        case 'number': {
            return CellComponent.number({
                value,
                formattedValue: Formatter.number(value),
            })
        }
        case 'status': {
            return CellComponent.status({
                value,
                formattedValue: Formatter.status(value),
            })
        }
        case 'time': {
            if (typeof value === 'string') {
                value = new Date(value)
            }
            return CellComponent.time({
                value,
                formattedValue: Formatter.time(value),
            })
        }
        case 'progressBar':
            return (
                <ProgressBar
                    width="100%"
                    height="1.5em"
                    numerator={formatNumber0(value?.numerator)}
                    denominator={formatNumber0(value?.denominator)}
                    formatNumber={Formatter.number}
                />
            )
        default:
            return value ? String(value) : ''
    }
}

export function getColumns({
    setIsAlertChangeColumns = null,
}): ColumnDef<IColumnDef>[] {
    const { cache } = useGlobalCache((state) => state)
    return [
        {
            accessorKey: 'project',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'project',
                title: 'Project',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.projects.map((project: any) => {
                            return {
                                label: project.jobNumber
                                    ? `${project.jobNumber}: ${project.name}`
                                    : project.name,
                                value: project.id,
                                status: project.status,
                            }
                        }),
                        (project: any) => _.toLower(project.label)
                    ),
                    groupBy: (sm) => sm.status,
                    sortGroups: (a, b) => {
                        const statusOrder = [
                            'active',
                            'prospective',
                            'onHold',
                            'archived',
                        ]
                        return (
                            statusOrder.indexOf(a.label) -
                            statusOrder.indexOf(b.label)
                        )
                    },
                },
            },
        },
        {
            accessorKey: 'projectPhase',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Phase"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Phase',
                visible: true,
            },
        },
        {
            accessorKey: 'projectStatus',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project Status"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'status',
                title: 'Project Status',
                visible: true,
                filterOptions: {
                    options: [
                        { label: 'Active', value: 'active' },
                        { label: 'On Hold', value: 'onHold' },
                        { label: 'Prospective', value: 'prospective' },
                        { label: 'Archived', value: 'archived' },
                    ],
                },
            },
        },
        {
            accessorKey: 'phaseStatus',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Phase Status"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'status',
                title: 'Phase Status',
                visible: true,
                filterOptions: {
                    options: [
                        { label: 'Active', value: 'active' },
                        { label: 'On Hold', value: 'onHold' },
                        { label: 'Prospective', value: 'prospective' },
                        { label: 'Archived', value: 'archived' },
                    ],
                },
            },
        },
        {
            accessorKey: 'staffMember',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'staffMember',
                title: 'Staff',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.staff.map((staff) => {
                            return {
                                label: `${staff.firstName} ${staff.lastName}`,
                                value: staff.id,
                                isArchived: staff.isArchived,
                            }
                        }),
                        (staff: any) => _.toLower(staff.label)
                    ),
                    groupBy: (sm) => (sm.isArchived ? 'Archived' : 'Active'),
                    sortGroups: (a, b) => (a.label === 'Archived' ? 1 : -1),
                },
            },
        },
        {
            accessorKey: 'task',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Task"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Task',
                visible: true,
            },
        },
        {
            accessorKey: 'date',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Date"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'date',
                title: 'Date',
                visible: true,
            },
            sortingFn: 'sortingDateFn',
        },
        {
            accessorKey: 'numMinutes',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'number',
                title: 'Hours',
                visible: true,
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'billableHours',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Billable Hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'number',
                title: 'Billable Hours',
                visible: true,
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'nonBillableHours',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Non-Billable Hours"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'number',
                title: 'Non-Billable Hours',
                visible: true,
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'percentBillable',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Percent Billable"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'percent',
                title: 'Percent Billable',
                visible: true,
            },
            aggregationFn: 'mean',
        },
        {
            accessorKey: 'notes',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Notes"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 240,
            minSize: 240,
            maxSize: 240,
            meta: {
                type: 'text',
                title: 'Notes',
                visible: true,
            },
        },
        {
            accessorKey: 'costCentre',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Cost Centre"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 120,
            minSize: 120,
            maxSize: 120,
            meta: {
                type: 'costCentre',
                title: 'Cost Centre',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.costCentres.map((costCentre: any) => {
                            return {
                                label: costCentre.name,
                                value: costCentre.id,
                            }
                        }),
                        (costCentre: any) => _.toLower(costCentre.label)
                    ),
                },
            },
        },
        {
            accessorKey: 'staffCostCentre',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff Cost Centre"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 120,
            minSize: 120,
            maxSize: 120,
            meta: {
                type: 'costCentre',
                title: 'Staff Cost Centre',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.costCentres.map((costCentre: any) => {
                            return {
                                label: costCentre.name,
                                value: costCentre.id,
                            }
                        }),
                        (costCentre: any) => _.toLower(costCentre.label)
                    ),
                },
            },
        },
        {
            accessorKey: 'projectOwner',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project Owner"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'staffMember',
                title: 'Project Owner',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.staff.map((staff) => {
                            return {
                                label: `${staff.firstName} ${staff.lastName}`,
                                value: staff.id,
                                isArchived: staff.isArchived,
                            }
                        }),
                        (staff: any) => _.toLower(staff.label)
                    ),
                    groupBy: (sm) => (sm.isArchived ? 'Archived' : 'Active'),
                    sortGroups: (a, b) => (a.label === 'Archived' ? 1 : -1),
                },
            },
        },
        {
            accessorKey: 'projectContact',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project Client"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'contact',
                title: 'Project Client',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.contacts.map((contact: any) => {
                            return {
                                label: `(${contact.organisationName})`,
                                value: contact.id,
                            }
                        }),
                        (contact: any) => _.toLower(contact.label)
                    ),
                },
            },
        },
        {
            accessorKey: 'projectInvoiceContact',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project Primary Contact"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'contact',
                title: 'Project Primary Contact',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.contacts.map((contact: any) => {
                            return {
                                label: `(${contact.organisationName})`,
                                value: contact.id,
                            }
                        }),
                        (contact: any) => _.toLower(contact.label)
                    ),
                },
                permissions: (row) => canViewPrimaryContact(),
            },
        },
        {
            accessorKey: 'projectCode',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Project Code"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Project Code',
                visible: true,
            },
        },
        {
            accessorKey: 'projectPhaseCode',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Phase Code"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Phase Code',
                visible: true,
            },
        },
        {
            accessorKey: 'staffMemberFirstName',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff First Name"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Staff First Name',
                visible: true,
            },
        },
        {
            accessorKey: 'staffMemberLastName',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff Last Name"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Staff Last Name',
                visible: true,
            },
        },
        {
            accessorKey: 'staffRole',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff Role"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'role',
                title: 'Staff Role',
                visible: true,
                filterOptions: {
                    options: _.sortBy(
                        cache.roles.map((role: any) => {
                            return {
                                label: `(${role.name})`,
                                value: role.id,
                            }
                        }),
                        (role: any) => _.toLower(role.label)
                    ),
                },
            },
        },
        {
            accessorKey: 'monthIndex',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Month"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'text',
                title: 'Month',
                visible: true,
                format: (month) =>
                    month &&
                    Formatter.month(parse(month, 'yyyy-MM', new Date())),
            },
        },
        {
            accessorKey: 'isBillable',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Is Billable"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Is Billable',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
            },
        },
        {
            accessorKey: 'isVariation',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Is Variation"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Is Variation',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
            },
        },
        {
            accessorKey: 'isOvertime',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Is Overtime"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Is Overtime',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
            },
        },
        {
            accessorKey: 'isLocked',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Is Locked"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Is Locked',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
            },
        },
        {
            accessorKey: 'beenInvoiced',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Has Been Invoiced"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Has Been Invoiced',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
            },
        },
        {
            accessorKey: 'flexi',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Flexi"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Flexi',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
                permissions: (row) => canViewFlexi(),
            },
        },
        {
            accessorKey: 'fee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Fee',
                visible: true,
                permissions: (r) =>
                    canViewProjectFees(SessionStore.user, r?.project),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'remote',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Remote"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Remote',
                visible: true,
                permissions: (r) =>
                    canViewRemote(SessionStore.user, r?.project),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'revenue',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Revenue"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Revenue',
                visible: true,
                permissions: (r) =>
                    canViewProjectFees(SessionStore.user, r?.project) &&
                    canViewProjectInvoices(SessionStore.user, r?.project),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'remainingFee',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Remaining Fee"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Remaining Fee',
                visible: true,
                permissions: (r) =>
                    canViewProjectFees(SessionStore.user, r?.project) &&
                    canViewProjectInvoices(SessionStore.user, r?.project),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'budget',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expense Budget"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Expense Budget',
                visible: true,
                permissions: (r) =>
                    canViewProjectExpenseBudgets(SessionStore.user, r?.project),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'hoursBudget',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Hours Budget"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'number',
                title: 'Hours Budget',
                visible: true,
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'staffHoursBudget',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Staff Hours Budget"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'number',
                title: 'Staff Hours Budget',
                visible: true,
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'actualVsStaffHoursBudget',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Actual vs Staff Hours Budget"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'progressBar',
                title: 'Actual vs Staff Hours Budget',
                visible: true,
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'startMinutes',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Start Time"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'time',
                title: 'Start Time',
                visible: true,
            },
        },
        {
            accessorKey: 'endMinutes',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="End Time"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'time',
                title: 'End Time',
                visible: true,
            },
        },
        {
            accessorKey: 'labourExpense',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Labour Expense"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Labour Expense',
                visible: true,
                permissions: (r) => canViewStaffPayRate(SessionStore.user),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'cost',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Expense (cost)"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Expense (cost)',
                visible: true,
                permissions: (r) => canViewStaffCostRate(SessionStore.user),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'chargeOut',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Charge Out"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Charge Out',
                visible: true,
                permissions: (r) =>
                    canViewStaffChargeOutRate(SessionStore.user),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'chargeOutRate',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Charge Out Rate"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'currency',
                title: 'Charge Out Rate',
                visible: true,
                permissions: (r) =>
                    canViewStaffChargeOutRate(SessionStore.user),
            },
            aggregationFn: 'sum',
        },
        {
            accessorKey: 'hasNotes',
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    setIsAlertChangeColumns={setIsAlertChangeColumns}
                    title="Has Notes"
                />
            ),
            cell: ({ getValue, row, column }) => {
                return renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                )
            },
            size: 160,
            minSize: 160,
            maxSize: 160,
            meta: {
                type: 'boolean',
                title: 'Has Notes',
                visible: true,
                filterOptions: {
                    options: [
                        {
                            label: `Yes`,
                            value: true,
                        },
                        {
                            label: `No`,
                            value: false,
                        },
                    ],
                },
            },
        },
    ]
}
