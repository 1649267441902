import { useDataTable } from '@2/hooks/use-data-table'
import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import * as React from 'react'
import { getColumns } from './timesheet-report-columns'
import { useTimeReportStore } from './time-report-store'
import { trpc } from '@/system/trpc'
import {
    ExportColumn,
    sortColumnsAsInStore,
    sortingDateFn,
    sortingProgressFn,
    sortingTextFn,
    typeFilterMap,
} from '@/version2/types'
import { TimeReportActions } from './time-report-actions'
import { DataTableColumnHeader } from '@/version2/components/data-table/data-table-column-header'
import { Check, ChevronRight } from 'lucide-react'
import { Checkbox } from '@/version2/components/ui/checkbox'
import { TimeReportTableFloatingBar } from './time-report-floating-bar'
import { Row, Table, RowSelectionState } from '@tanstack/react-table'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/version2/components/ui/popover'
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@2/components/ui/command'
import { cn } from '@/lib/utils'
// Helper to check the selection state of subrows
const getSubRowSelectionState = (
    row: Row<any>,
    selection: RowSelectionState,
    table: Table<any>
): 'none' | 'some' | 'all' => {
    const subRows = row.getLeafRows()
    if (!subRows.length) return 'none'

    const selectedCount = subRows.filter(
        (subRow) => selection[subRow.id]
    ).length

    if (selectedCount === 0) return 'none'
    if (selectedCount === subRows.length) return 'all'
    return 'some'
}

// Mutate row selection state
const mutateRowSelection = (
    selection: RowSelectionState,
    rowId: string,
    value: boolean,
    includeChildren: boolean,
    table: Table<any>
): void => {
    // Set the current row's selection state
    selection[rowId] = value

    // Handle child rows if requested
    if (includeChildren) {
        const row = table.getRow(rowId)
        row.getLeafRows().forEach((subRow) => {
            if (subRow.getCanSelect()) {
                selection[subRow.id] = value
            }
        })
    }
}

// Sync parent row selection based on children
const syncParentRowSelection = (
    row: Row<any>,
    selection: RowSelectionState,
    table: Table<any>
): RowSelectionState => {
    const parentRow = row.getParentRow()
    if (!parentRow) {
        return selection
    }

    const subRowState = getSubRowSelectionState(parentRow, selection, table)

    // Update parent selection state
    if (subRowState === 'all') {
        selection[parentRow.id] = true
    } else if (subRowState === 'none') {
        selection[parentRow.id] = false
    } else {
        // For 'some', we set it to false in the selection state
        // The indeterminate visual state will be handled by the Checkbox component
        selection[parentRow.id] = false
    }

    // Recursively update all ancestors
    return syncParentRowSelection(parentRow, selection, table)
}

export function TimesheetReportTable() {
    const [isAlertChangeColumns, setIsAlertChangeColumns] =
        React.useState(false)
    const {
        setReportData,
        isLoadingReportData,
        setIsLoadingReportData,
        setOrganisationReport,
        organisationReport,
        reportData,
        setColumns,
        setIsHideColumn,
        isHideColumn,
    } = useTimeReportStore((state) => state)

    const columns = getColumns({ setIsAlertChangeColumns }).sort(
        sortColumnsAsInStore(organisationReport.columns)
    )

    const filterFields: any = columns.map((column: any) => {
        return {
            label: column?.meta?.title,
            value: column.accessorKey,
            typeFilter: typeFilterMap[column?.meta?.type],
            ...column?.meta?.filterOptions,
        }
    })

    const [rowExpand, setRowExpand] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const toggleRowsAtDepth = (
        table: Table<any>,
        targetDepth: number,
        isExpand: boolean
    ) => {
        const allRows = table
            .getFilteredRowModel()
            .rows.flatMap((row) => [row, ...row.getLeafRows()])
        allRows.forEach((row) => {
            if (row.depth < targetDepth) {
                row.toggleExpanded(isExpand)
            }
            if (isExpand && row.depth >= targetDepth) {
                row.toggleExpanded(!isExpand)
            }
        })
    }
    const exportColumns: ExportColumn[] = []
    organisationReport.groupBy.forEach((groupByColumn) => {
        exportColumns.push({
            value: groupByColumn,
            label: columns.find(
                (column) => column.accessorKey === groupByColumn
            )?.meta?.title,
        })
    })
    exportColumns.push({
        label: 'Time Entry',
        value: 'timeEntry',
    })

    const defaultSort = [
        {
            id: 'label',
            desc: false,
        },
    ]

    const { table } = useDataTable({
        data: reportData,
        defaultSort,
        columns: [
            {
                id: 'expand',
                header: ({ table }) => {
                    return (
                        <>
                            <Popover open={open} onOpenChange={setOpen}>
                                <PopoverTrigger asChild>
                                    <ChevronRight
                                        size={18}
                                        className={`transition-transform duration-300 ${open ? 'rotate-90' : ''} cursor-pointer`}
                                    />
                                </PopoverTrigger>
                                <PopoverContent
                                    className="w-[20rem] p-0"
                                    align="start"
                                >
                                    <Command>
                                        <CommandList>
                                            <CommandGroup>
                                                {exportColumns.map(
                                                    (column, index) => (
                                                        <CommandItem
                                                            key={String(
                                                                column.value
                                                            )}
                                                            className="capitalize"
                                                            value={String(
                                                                column.value
                                                            )}
                                                            onSelect={(
                                                                currentValue
                                                            ) => {
                                                                setRowExpand(
                                                                    currentValue ===
                                                                        rowExpand
                                                                        ? ''
                                                                        : currentValue
                                                                )
                                                                toggleRowsAtDepth(
                                                                    table,
                                                                    index,
                                                                    !(
                                                                        currentValue ===
                                                                        rowExpand
                                                                    )
                                                                )
                                                                setOpen(false)
                                                            }}
                                                        >
                                                            <Check
                                                                className={cn(
                                                                    'mr-2 h-4 w-4',
                                                                    rowExpand ===
                                                                        column.value
                                                                        ? 'opacity-100'
                                                                        : 'opacity-0'
                                                                )}
                                                            />
                                                            {column.label}
                                                        </CommandItem>
                                                    )
                                                )}
                                            </CommandGroup>
                                            <CommandSeparator />
                                        </CommandList>
                                    </Command>
                                </PopoverContent>
                            </Popover>
                        </>
                    )
                },
                cell: ({ row }) => {
                    return row.getCanExpand() ? (
                        <div
                            className="cursor-pointer"
                            onClick={() => row.toggleExpanded()}
                        >
                            <ChevronRight
                                size={18}
                                className={`transition-transform duration-300 ${
                                    row.getIsExpanded() ? 'rotate-90' : ''
                                }`}
                            />
                        </div>
                    ) : null
                },
                size: 40,
                minSize: 34,
            },
            {
                id: 'select',
                header: ({ table }) => {
                    return (
                        <div className="flex justify-center">
                            <Checkbox
                                checked={
                                    table.getIsAllRowsSelected()
                                        ? true
                                        : table.getIsSomeRowsSelected()
                                          ? 'indeterminate'
                                          : false
                                }
                                onCheckedChange={(checked) => {
                                    // Convert the checked value to a boolean and pass to the table handler
                                    table.toggleAllRowsSelected(!!checked)
                                }}
                                aria-label="Select all"
                                style={{ borderColor: '#000' }}
                            />
                        </div>
                    )
                },
                cell: ({ row, table }) => {
                    // Get the selection state for this row's children
                    const subRowState = row.getLeafRows().length
                        ? getSubRowSelectionState(
                              row,
                              table.getState().rowSelection,
                              table
                          )
                        : 'none'

                    return (
                        <div className="flex justify-center">
                            <Checkbox
                                checked={
                                    row.getIsSelected()
                                        ? true
                                        : subRowState === 'some'
                                          ? 'indeterminate'
                                          : false
                                }
                                // Add indeterminate state when some children are selected]
                                onCheckedChange={(checked) => {
                                    table.setRowSelection((old) => {
                                        const newSelection = { ...old }

                                        // Update this row and its children
                                        mutateRowSelection(
                                            newSelection,
                                            row.id,
                                            !!checked,
                                            true, // include children
                                            table
                                        )

                                        // Sync parent rows
                                        return syncParentRowSelection(
                                            row,
                                            newSelection,
                                            table
                                        )
                                    })
                                }}
                                aria-label="Select row"
                                style={{ borderColor: '#000' }}
                            />
                        </div>
                    )
                },
                enableSorting: false,
                enableHiding: false,
                size: 40,
                minSize: 34,
            },
            {
                id: 'label',
                accessorKey: 'label',
                ...({ column }) =>
                    columns.find(
                        (item) =>
                            item.accessorKey ===
                            organisationReport.groupBy[column.depth]
                    ),
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        setIsAlertChangeColumns={setIsAlertChangeColumns}
                        title="Label"
                    />
                ),
                enableHiding: false,
                sortingFn: 'text',
            },
            ...columns,
        ],
        visibleColumns: ['label', ...organisationReport.columns],
        filterFields,
        enableAdvancedFilter: true,
        sortingFns: {
            sortingTextFn,
            sortingProgressFn,
            sortingDateFn,
        },
    })

    const fetchReportData = async (data: any) => {
        return await trpc.timeReport.getReportsData.query(data)
    }

    return (
        <DataTable
            table={table}
            showTotals={true}
            floatingBar={<TimeReportTableFloatingBar table={table} />}
            loadingReportData={isLoadingReportData}
            hideColumns={organisationReport.groupBy}
        >
            <DataTableAdvancedToolbar
                table={table}
                filterFields={filterFields}
                isAlertChangeColumns={isAlertChangeColumns}
                setIsAlertChangeColumns={setIsAlertChangeColumns}
                setOrganisationReport={setOrganisationReport}
                setReportData={setReportData}
                setIsLoadingReportData={setIsLoadingReportData}
                fetchReportData={fetchReportData}
                organisationReport={organisationReport}
                setColumnsStore={setColumns}
                setIsHideColumn={setIsHideColumn}
                isHideColumn={isHideColumn}
                isHaveGroup={true}
                fixedColumns={['select', 'label']}
                showSearchFieldHeader={false}
                showApplyToPhase={false}
            >
                <TimeReportActions
                    table={table}
                    exportColumns={exportColumns}
                />
            </DataTableAdvancedToolbar>
        </DataTable>
    )
}
