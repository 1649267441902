import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { Fraction, OrganisationReport } from '@/version2/types'

interface Project {
    projectId: string
    title: string
    startDate: string
    isRootPhase: boolean | null
    name: string
    actualVsBudgetedHours: Fraction
    actualVsBudgetedExpenses: Fraction
    revenueVsFee: Fraction
    profit: number
    profitMargin: number | null
    status: string
    hoursBudgeted: number
    recordedHoursAllTime: number
    expensesAllTime: number
    expenseBudget: number
    revenueAllTime: number
    fee: number
    revenue: number
    expensesProject: number
    expenses: number
    projectExpenses: number
}

interface ProjectReportData extends Project {
    children: Project[]
    key: string
    label: string
}

interface ProjectReportState {
    reportData: ProjectReportData[] | []
    organisationReport: OrganisationReport
    isLoadingReportData: boolean
    isHideColumn: boolean
    columns: string[]
    setReportData: (
        newReportData: ProjectReportData[] | []
    ) => void
    setOrganisationReport: (newProjectReport: OrganisationReport) => void
    setIsLoadingReportData: (isLoading: boolean) => void
    setIsHideColumn: (isHideColumn: boolean) => void
    setColumns: (newColumns: string[]) => void
}

export const useProjectReportStore = create<ProjectReportState>()(
    devtools(
        immer((set, get) => ({
            reportData: null,
            organisationReport: null,
            isLoadingReportData: false,
            isHideColumn: false,
            columns: null,
            setOrganisationReport: (newProjectReport: OrganisationReport) =>
                set({ organisationReport: newProjectReport }),
            setReportData: (
                newReportData: ProjectReportData[] | []
            ) => set({ reportData: newReportData }),
            setIsLoadingReportData: (isLoading: boolean) =>
                set({ isLoadingReportData: isLoading }),
            setIsHideColumn: (isHide: boolean) => set({ isHideColumn: isHide }),
            setColumns: (newColumns: string[]) =>
                set((state) => {
                    if (state.organisationReport) {
                        state.organisationReport.columns = newColumns
                    }
                }),
        }))
    )
)

export { ProjectReportData }
