import { createFileRoute } from '@tanstack/react-router'
import TimesheetReportsPageHeader from '../../Pages/TimesheetReportsPage/TimesheetReportsPageHeader'
import TimesheetReportsPage from '../../Pages/TimesheetReportsPage/TimesheetReportsPage'
import PageBody from '../../Pages/Layout/PageBody'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { qf } from '../../Queries/queryFormatter'
import { TimesheetReportsPageV2 } from '@2/pages/timesheet-report/timesheet-report-page'
import { useTimeReportStore } from '@2/pages/timesheet-report/time-report-store'
import { TimeReportColumns } from '../../reports/Time/TimeReportColumns'
import { trpc } from '../../system/trpc'
import { dateStringLookup } from '../../Components/Filters'
import { queryClient } from '../../App'
import { useLayoutStore } from '@2/layout/layout-store'
import { useGlobalCache } from '@2/cache'
import { format } from 'date-fns'

export const Route = createFileRoute('/_private/timesheets/report')({
    beforeLoad: ({ params, search }) => {
        if (!canViewRoute(SessionStore.user, 'timesheetReports', params))
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            const cache = useGlobalCache.getState()
            const report = cache.getItemById(
                'organisationReports',
                search.report
            )
            layoutStore.setDefaults({
                pageTitle: report?.name || 'Timesheet Report',
                subMenu: 'time',
            })
        }
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    component: Wrapper,
})

const loader = async ({ report }) => {
    const reportId = report || SessionStore?.organisation?.defaultTimeReportId
    const collections = [
        reportId
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
        {
            collection: 'contacts',
            fields: ['firstName', 'lastName', 'organisationName'],
        },
    ].filter(Boolean)
    if (usingNewLogin()) {
        const res = reportId
            ? await trpc.timeReport.getOrganisationReport.query({
                  reportId,
              })
            : null

        const organisationReport =
            res || SessionStore.organisation.defaultTimeReport
        let dateRange = [null, null]
        if (organisationReport.options?.dateRange) {
            dateRange =
                dateStringLookup[organisationReport.options?.dateRange]?.(
                    organisationReport.options?.fortnightType
                ) || organisationReport.options?.dateRange
        }
        const { columns, filters, groupBy, sortBy, options, name } =
            organisationReport
        useTimeReportStore.getState().setOrganisationReport({
            columns,
            filters,
            groupBy,
            organisationId: SessionStore.organisationId,
            sortBy,
            dateRange,
            invoiceDateType: SessionStore.settings.reportInvoiceDateType,
            id: reportId,
            options,
            name,
        })
        const cacheData = await queryClient.fetchQuery({
            queryKey: ['timeReportCache'],
            queryFn: () =>
                trpc.timeReport.getReportsData.query({
                    organisationId: SessionStore.organisationId,
                    userId: SessionStore.user?.id,
                    columns: organisationReport?.columns.filter(
                        (c) =>
                            !TimeReportColumns(reportId)[c]?.permissions ||
                            TimeReportColumns(reportId)[c].permissions()
                    ),
                    filters: organisationReport?.filters.map((f) => {
                        if (
                            TimeReportColumns(reportId)[f.column]?.type ===
                            'date'
                        ) {
                            let value = dateStringLookup[f.value]?.() || f.value
                            if (Array.isArray(value)) {
                                value = value.map((d) =>
                                    d ? format(d, 'yyyy-MM-dd') : null
                                )
                            } else {
                                value = value
                                    ? format(value, 'yyyy-MM-dd')
                                    : null
                            }
                            return {
                                ...f,
                                value,
                            }
                        } else if (
                            TimeReportColumns(reportId)[f.column]?.type ===
                            'time'
                        ) {
                            let value = f.value
                            if (Array.isArray(value)) {
                                value = value.map((d) => {
                                    d = new Date(d)
                                    return d && d.getTime()
                                        ? format(d, 'yyyy-MM-dd HH:mm')
                                        : null
                                })
                            } else {
                                value = new Date(value)
                                value =
                                    value && value.getTime()
                                        ? format(value, 'yyyy-MM-dd HH:mm')
                                        : null
                            }
                            return {
                                ...f,
                                value,
                            }
                        } else {
                            return f
                        }
                    }),
                    sortBy: organisationReport?.sortBy || [],
                    groupBy: organisationReport?.groupBy || [],
                    dateRange,
                    invoiceDateType:
                        SessionStore.settings.reportInvoiceDateType,
                }),
            staleTime: 5 * 60 * 1000,
        })

        useTimeReportStore.getState().setReportData(cacheData)
    }

    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return <TimesheetReportsPageV2 />
    }
    return (
        <>
            <TimesheetReportsPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <TimesheetReportsPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
        </>
    )
}
