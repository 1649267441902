import { useDataTable } from '@2/hooks/use-data-table'
import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import _ from 'lodash'

import { getColumns } from './project-report-columns'
import { ProjectReportActions } from './project-report-actions'
import { ProjectReportData, useProjectReportStore } from './project-report-store'
import * as React from 'react'
import { trpc } from '@/system/trpc'
import { ExportColumn, sortColumnsAsInStore, sortingDateFn, sortingProgressFn, sortingTextFn, typeFilterMap } from '@/version2/types'

export function ProjectReportTable() {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    // const { featureFlags } = useTasksTable()

    // const { data, pageCount } = React.use(tasksPromise)

    // Memoize the columns so they don't re-render on every render
    // const columns = React.useMemo(() => getColumns(), [])

    /**
     * This component can render either a faceted filter or a search filter based on the `options` prop.
     *
     * @prop options - An array of objects, each representing a filter option. If provided, a faceted filter is rendered. If not, a search filter is rendered.
     *
     * Each `option` object has the following properties:
     * @prop {string} label - The label for the filter option.
     * @prop {string} value - The value for the filter option.
     * @prop {React.ReactNode} [icon] - An optional icon to display next to the label.
     * @prop {boolean} [withCount] - An optional boolean to display the count of the filter option.
     */
    // const data = ProjectListStore.queryData || []
    const {
        setReportData,
        isLoadingReportData,
        setIsLoadingReportData,
        organisationReport,
        setOrganisationReport,
        reportData,
        setColumns,
        isHideColumn,
        setIsHideColumn,
    } = useProjectReportStore((state) => state)

    const [isAlertChangeColumns, setIsAlertChangeColumns] =
        React.useState(false)
    const columns = getColumns({ setIsAlertChangeColumns }).sort(sortColumnsAsInStore(organisationReport.columns))

    const filterFields: any = columns
        .filter((column: any) => column.accessorKey !== 'expand')
        .map((column: any) => {
            return {
                label: column?.meta?.title,
                value: column.accessorKey,
                typeFilter: typeFilterMap[column?.meta?.type],
                ...column?.meta?.filterOptions,
            }
        })

    const { table } = useDataTable({
        data: reportData,
        columns,
        visibleColumns: organisationReport.columns,
        // pageCount,
        // defaultPerPage: 100000,
        // defaultSort: 'createdAt.desc',
        filterFields,
        enableAdvancedFilter: true,
        sortingFns: {
            sortingTextFn,
            sortingProgressFn,
            sortingDateFn,
        },
    })

    const fetchReportData = async (data: any) => {
        return await trpc.projectReport.getReportsData.query(data);
    };

    const exportColumns: ExportColumn[] = [
        {
            label: 'Project',
            value: 'project'
        },
        {
            label: 'Phase',
            value: 'phase'
        },
    ]

    return (
        <DataTable
            table={table}
            showTotals={true}
            loadingReportData={isLoadingReportData}
        >
            <DataTableAdvancedToolbar
                table={table}
                filterFields={filterFields}
                isAlertChangeColumns={isAlertChangeColumns}
                setIsAlertChangeColumns={setIsAlertChangeColumns}
                setOrganisationReport={setOrganisationReport}
                setReportData={setReportData}
                setIsLoadingReportData={setIsLoadingReportData}
                organisationReport={organisationReport}
                fetchReportData={fetchReportData}
                setColumnsStore={setColumns}
                setIsHideColumn={setIsHideColumn}
                isHideColumn={isHideColumn}
                isHaveGroup={false}
                groupByColumns={['projectId']}
            >
                <ProjectReportActions table={table} exportColumns={exportColumns}/>
            </DataTableAdvancedToolbar>
        </DataTable>
    )
}
