import { ProjectReportTable } from '@/version2/pages/project-report/project-report-table'
import { ProjectReportTableProvider } from '@/version2/pages/project-report/project-report-table-provider'
import { TooltipProvider } from '@2/components/ui/tooltip'

export const ProjectReportPage = () => {
    return (
        <TooltipProvider>
            <ProjectReportTableProvider>
                <ProjectReportTable />
            </ProjectReportTableProvider>
        </TooltipProvider>
    )
}
