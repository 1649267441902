import { durationToUnit } from '@ryki/datemath'

export default {
    boolean: (value) => value,
    text: (value) => (value ? String(value).toLowerCase() : ''),
    number: (value) => value ?? Infinity,
    currency: (value) => value ?? Infinity,
    percent: (value) => value ?? Infinity,
    durationMinutes: (value) => value ?? Infinity,
    date: (value) =>
        value instanceof Date && !isNaN(value) ? value?.getTime() : Infinity,
    time: (value) => value ?? Infinity,
    duration: (value) =>
        value?.value && value?.unit
            ? durationToUnit(value, 'milliseconds')
            : Infinity,
    progress: (value) =>
        value?.denominator ? value.numerator / value.denominator : Infinity,
    progressBar: (value) =>
        value?.denominator ? value.numerator / value.denominator : Infinity,
    project: (value) => value?.title ?? value ?? '',
    phase: (value) => value?.title ?? value ?? '',
    costCentre: (value) => value?.name ?? value ?? '',
    contact: (value) => value?.label ?? value ?? '',
    staff: (value) => value?.fullName ?? value ?? '',
    staffMember: (value) => value?.fullName ?? value ?? '',
    role: (value) => value?.name ?? value ?? '',
    status: (value) => value ?? '',
}
