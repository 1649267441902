import { createRootRoute, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import ErrorComponent from '../Components/ErrorComponent'
import LoadingSpinner from '../Components/LoadingSpinner'
const isDev = process.env.REACT_APP_ENV === 'development'

export const Route = createRootRoute({
    component: RootComponent,
    pendingComponent: LoadingSpinner,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})

function RootComponent() {
    return (
        <>
            <Outlet />
            {/* {isDev ? <TanStackRouterDevtools /> : null} */}
        </>
    )
}
