import { Calendar as CalendarIcon } from 'lucide-react'
import { cn } from '@2/lib/utils'
import { Button } from '@2/components/ui/button'
import { Calendar } from '@2/components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'
import { useState } from 'react'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
interface DatePickerProps {
    value: Date | undefined
    onChange: (date: Date | undefined) => void
    className?: string
    popoverClassName?: string
}

export function DatePicker({
    value,
    onChange,
    className,
    popoverClassName,
}: DatePickerProps) {
    const [open, setOpen] = useState(false)

    const handleSelect = (date: Date | undefined) => {
        onChange(date)
        setOpen(false)
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    className={cn(
                        'w-full justify-start text-left font-normal',
                        !value && 'text-muted-foreground',
                        className
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {value ? (
                        <span>{FormatDate(value)}</span>
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className={cn('w-auto p-0', popoverClassName)}>
                <Calendar
                    mode="single"
                    selected={value}
                    onSelect={handleSelect}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}

export default DatePicker
