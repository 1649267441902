import { useState } from 'react'
import { Settings, LayoutDashboard, Import, BookUser } from 'lucide-react'
import { useGlobalCache } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'
export const ClientsSubNav = () => {
    const [expandedList, setExpandedList] = useState('contacts')
    const queryCache = useGlobalCache((state) => state.queryCache)
    const clients = queryCache('contacts')

    const lists = [
        {
            id: 'contacts',
            title: 'Clients',
            icon: BookUser,
            items: (clients || [])
                .map((c) => ({
                    label: `${c.firstName} ${c.lastName} (${c.organisationName})`,
                    value: c.id,
                    route: `/contacts/${c.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Clients</SubNavigationHeading>
            <SubNavigationBody>
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        items={list.items}
                        isExpanded={expandedList === list.id}
                        icon={list.icon}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
            </SubNavigationBody>
            <SubNavigationFooter>
                <SideNavButton
                    icon={Import}
                    label="Import Clients"
                    onClick={() => null}
                />
            </SubNavigationFooter>
        </SubNavigationSidebar>
    )
}
