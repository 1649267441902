import SessionStore from '../State/SessionStore'

const getPhaseTitle = (phase) => {
    return phase.jobNumber && phase.name
        ? `${phase.jobNumber}: ${phase.name}`
        : phase.name || phase.jobNumber
}

export default (a, b) => {
    // Check if either phase is a root phase
    if (a?.isRootPhase && !b?.isRootPhase) {
        return 1 // a is root, so it should be last
    } else if (!a?.isRootPhase && b?.isRootPhase) {
        return -1 // b is root, so a should come first
    }

    // First check if both have positions
    if (a.position !== undefined && b.position !== undefined) {
        return a.position - b.position
    }
    // If only one has a position, it should come first
    if (a.position !== undefined) return -1
    if (b.position !== undefined) return 1

    if (SessionStore.settings.sortPhasesBy === 'startDate') {
        // Check if both items have a startDate
        if (a.startDate && b.startDate) {
            // If both have a startDate, first sort by startDate
            if (a.startDate !== b.startDate) {
                return (
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime()
                )
            }
            // If startDate is the same, sort by title
            const titleA = getPhaseTitle(a)
            const titleB = getPhaseTitle(b)
            return titleA?.localeCompare?.(titleB)
        } else if (a.startDate) {
            // If only a has a startDate, it should come first
            return -1
        } else if (b.startDate) {
            // If only b has a startDate, it should come first
            return 1
        }
    }

    // If neither has a startDate, sort by title
    const titleA = getPhaseTitle(a)
    const titleB = getPhaseTitle(b)
    return titleA?.toLowerCase?.()?.localeCompare?.(titleB?.toLowerCase?.())
}
