import { useState } from 'react'
import {
    Settings,
    LayoutDashboard,
    LineChart,
    PencilRuler,
    Handshake,
    OctagonPause,
    Archive,
} from 'lucide-react'
import { useGlobalCache } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'

const getProjectTitle = (project) =>
    project.jobNumber ? `${project.jobNumber}: ${project.name}` : project.name

export const ProjectsSubNav = () => {
    const [expandedList, setExpandedList] = useState('reports')

    // Create a single selector for all the data we need
    const { projectReports, projectsByStatus } = useGlobalCache((state) => ({
        projectReports:
            state.cache.organisationReports?.filter(
                (r) => r.type === 'project'
            ) ?? [],
        projectsByStatus: {
            active:
                state.cache.projects?.filter((p) => p.status === 'active') ??
                [],
            prospective:
                state.cache.projects?.filter(
                    (p) => p.status === 'prospective'
                ) ?? [],
            onHold:
                state.cache.projects?.filter((p) => p.status === 'onHold') ??
                [],
            archived:
                state.cache.projects?.filter((p) => p.status === 'archived') ??
                [],
        },
    }))

    const lists = [
        {
            id: 'reports',
            title: 'Project Reports',
            icon: LineChart,
            rootRoute: '/projects',
            items: projectReports
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/projects?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'active',
            title: 'Active Projects',
            icon: PencilRuler,
            items: projectsByStatus.active
                .map((pr) => ({
                    label: getProjectTitle(pr),
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'prospective',
            title: 'Prospective Projects',
            icon: Handshake,
            items: projectsByStatus.prospective
                .map((pr) => ({
                    label: getProjectTitle(pr),
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'onhold',
            title: 'On Hold Projects',
            icon: OctagonPause,
            items: projectsByStatus.onHold
                .map((pr) => ({
                    label: getProjectTitle(pr),
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'archived',
            title: 'Archived Projects',
            icon: Archive,
            items: projectsByStatus.archived
                .map((pr) => ({
                    label: getProjectTitle(pr),
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Projects</SubNavigationHeading>
            <SubNavigationBody>
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        rootRoute={list.rootRoute}
                        items={list.items}
                        icon={list.icon}
                        isExpanded={expandedList === list.id}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
            </SubNavigationBody>
            <SubNavigationFooter>
                <SideNavButton
                    icon={LayoutDashboard}
                    label="Project Templates"
                    onClick={() => null}
                />
                <SideNavButton
                    icon={Settings}
                    label="Project Settings"
                    onClick={() => null}
                />
            </SubNavigationFooter>
        </SubNavigationSidebar>
    )
}
