import { TooltipProvider } from '@2/components/ui/tooltip'
import { TimesheetReportTable } from './timesheet-report-table'
import { TimeReportTableProvider } from './time-report-table-provider'

export const TimesheetReportsPageV2 = () => {
    return (
        <TooltipProvider>
            <TimeReportTableProvider>
                <TimesheetReportTable />
            </TimeReportTableProvider>
        </TooltipProvider>
    )
}
