import { createFileRoute, notFound } from '@tanstack/react-router'
import InvoicePageHeader from '../../Pages/InvoicePage/InvoicePageHeader'
import InvoicePage from '../../Pages/InvoicePage/InvoicePage'
import PageBody from '../../Pages/Layout/PageBody'
import { idf, qf } from '../../Queries/queryFormatter'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import DataStore from '../../State/DataStore'
import { router } from '../../App'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import { useLayoutStore } from '@2/layout/layout-store'
import { useGlobalCache } from '@2/cache'

export const Route = createFileRoute('/_private/invoices/$id/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'invoice', params))
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            const cache = useGlobalCache.getState()
            const invoice = cache.getItemById('invoices', params.id)
            layoutStore.setDefaults({
                pageTitle: invoice?.ref || 'Invoice',
                subMenu: 'invoices',
            })
        }
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ params }) => await loader(params.id),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
    onLeave: (match) => {
        const { id } = match.params
        const invoice = InvoiceCollection.invoicesById[id]
        if (
            !invoice?.createdAt &&
            match.pathname !== router.latestLocation.pathname
        ) {
            DataStore.destroyNewItems('invoiceId', id)
            DataStore.startSave()
        }
    },
})

const loader = async (id) => {
    // check if id is string or number
    let idFilter = idf(id)
    if (!isNaN(Number(id))) {
        idFilter = `oldId == ${qf(id)}`
    }
    const collections = [
        {
            collection: 'invoices',
            fields: [
                'oldId',
                'ref',
                'projectId',
                'dueDate',
                'startDate',
                'endDate',
                'issueDate',
                'contactId',
                'taxRatePercent',
                'accountingSystemId',
                'accountingSystemInvoiceId',
                'cachedData',
            ],
            filters: [idFilter],
            chain: [
                {
                    collection: 'invoiceLineItems',
                    join: { invoices: 'id', invoiceLineItems: 'invoiceId' },
                    fields: [
                        'createdAt',
                        'description',
                        'invoiceId',
                        'projectId',
                        'phaseId',
                        'billingType',
                        'lineItemType',
                        'unitCost',
                        'unitQuantity',
                        'isTaxed',
                        'staffIds',
                        'timesheetIds',
                        'expenseId',
                        'position',
                    ],
                },
                {
                    collection: 'projects',
                    join: { invoices: 'projectId', projects: 'id' },
                    fields: ['jobNumber', 'name', 'rootPhaseId'],
                },
                {
                    collection: 'phases',
                    join: { invoices: 'projectId', phases: 'projectId' },
                    fields: [
                        'jobNumber',
                        'name',
                        'projectId',
                        'startDate',
                        'fee',
                        'isRootPhase',
                        'position',
                    ],
                },
                {
                    collection: 'projectExpenses',
                    join: {
                        invoices: 'projectId',
                        projectExpenses: 'projectId',
                    },
                    fields: ['name', 'projectId', 'phaseId'],
                },
            ],
        },
        {
            collection: 'invoiceDescriptionTemplates',
            fields: ['name', 'description'],
        },
        {
            collection: 'contacts',
            fields: ['firstName', 'lastName', 'organisationName'],
        },
    ].filter(Boolean)

    const data = await Promise.all(collections.map(fetchData))
    const invoice =
        InvoiceCollection.invoicesById[id] ||
        InvoiceCollection.invoicesByOldId[id]
    if (!invoice) {
        throw notFound()
    }
    return data
}

function Wrapper() {
    const { id } = Route.useParams()
    if (migratedToV2()) {
        return <InvoicePage id={id} />
    }
    return (
        <>
            <InvoicePageHeader id={id} />
            <PageBody>
                <InvoicePage id={id} />
            </PageBody>
        </>
    )
}
