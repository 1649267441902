import { dateStringLookup } from '../Components/Filters'
import { qf } from './queryFormatter'

const boolOrDefault = (columnId) =>
    `(${columnId} == null ? false : ${columnId})`
const textOrDefault = (columnId) => `(${columnId} == null ? "" : ${columnId})`
const numberOrDefault = (columnId) => `(${columnId} == null ? 0 : ${columnId})`

export const queryFilters = {
    boolean: {
        '=': (column, value) =>
            value != null ? [`${column} == (${value})`] : null,
        '!=': (column, value) =>
            value != null ? [`${column} != (${value})`] : null,
    },
    text: {
        '=': (column, value) => [
            `${textOrDefault(column)} == "${value || ''}"`,
        ],
        '!=': (column, value) => [
            `${textOrDefault(column)} != "${value || ''}"`,
        ],
        contains: (column, value) =>
            value ? [`contains(${textOrDefault(column)}, "%${value}%")`] : [],
        '!contains': (column, value) =>
            value
                ? [`not(contains(${textOrDefault(column)}, "%${value}%"))`]
                : [],
        starts: (column, value) =>
            value ? [`contains(${textOrDefault(column)}, "${value}%")`] : [],
        '!starts': (column, value) =>
            value
                ? [`not(contains(${textOrDefault(column)}, "${value}%"))`]
                : [],
        ends: (column, value) =>
            value ? [`contains(${textOrDefault(column)}, "%${value}")`] : [],
        '!ends': (column, value) =>
            value
                ? [`not(contains(${textOrDefault(column)}, "%${value}"))`]
                : [],
        in: (column, value) =>
            value ? [`${textOrDefault(column)} in ${qf(value)}`] : [],
        '!in': (column, value) =>
            value ? [`not(${textOrDefault(column)} in ${qf(value)})`] : [],
    },
    number: {
        '=': (column, value) => [
            `${numberOrDefault(column)} == (${value || 0})`,
        ],
        '!=': (column, value) => [
            `${numberOrDefault(column)} != (${value || 0})`,
        ],
        '>': (column, value) => [
            `${numberOrDefault(column)} > (${value || 0})`,
        ],
        '>=': (column, value) => [
            `${numberOrDefault(column)} >= (${value || 0})`,
        ],
        '<': (column, value) => [
            `${numberOrDefault(column)} < (${value || 0})`,
        ],
        '<=': (column, value) => [
            `${numberOrDefault(column)} <= (${value || 0})`,
        ],
        between: (column, value) =>
            value
                ? [
                      `${numberOrDefault(column)} >= (${
                          value[0]
                      }) and ${numberOrDefault(column)} <= (${value[1]})`,
                  ]
                : [],
        '!between': (column, value) =>
            value
                ? [
                      `not(${numberOrDefault(column)} >= (${
                          value[0]
                      }) and ${numberOrDefault(column)} <= (${value[1]}))`,
                  ]
                : [],
        in: (column, value) =>
            value ? [`${numberOrDefault(column)} in ${qf(value)}`] : [],
        '!in': (column, value) =>
            value ? [`not(${numberOrDefault(column)} in ${qf(value)})`] : [],
    },
    date: {
        '=': (column, value) => (value ? [`${column} == ${qf(value)}`] : []),
        '!=': (column, value) => (value ? [`${column} != ${qf(value)}`] : []),
        '>': (column, value) => (value ? [`${column} > ${qf(value)}`] : []),
        '>=': (column, value) => (value ? [`${column} >= ${qf(value)}`] : []),
        '<': (column, value) => (value ? [`${column} < ${qf(value)}`] : []),
        '<=': (column, value) => (value ? [`${column} <= ${qf(value)}`] : []),
        between: (column, value) => {
            value = dateStringLookup[value]?.() || value
            return value
                ? [
                      `${column} >= ${qf(value[0])} and ${column} <= ${qf(
                          value[1]
                      )}`,
                  ]
                : []
        },
        '!between': (column, value) => {
            value = dateStringLookup[value]?.() || value
            return value
                ? [
                      `not(${column} >= ${qf(value[0])} and ${column} <= ${qf(
                          value[1]
                      )})`,
                  ]
                : []
        },
    },
    singleId: {
        '=': (column, value) => [`${column} == ${qf(value)}`],
        '!=': (column, value) => [`${column} != ${qf(value)}`],
        in: (column, value) =>
            value?.length ? [`${column} in ${qf(value)}`] : [],
        '!in': (column, value) =>
            value?.length ? [`not(${column} in ${qf(value)})`] : [],
    },
    manyIds: {
        in: (column, value) =>
            value?.length
                ? [value.map((v) => [`${v} in ${column}`]).join(' or ')]
                : [],
        '!in': (column, value) =>
            value?.length
                ? [value.map((v) => [`not(${v} in ${column}})`]).join(' and ')]
                : [],
    },
}
