import { createFileRoute } from '@tanstack/react-router'
import InvoiceListPageHeader from '../../Pages/InvoiceListPage/InvoiceListPageHeader'
import InvoiceListPage from '../../Pages/InvoiceListPage/InvoiceListPage'
import PageBody from '../../Pages/Layout/PageBody'
import { qf } from '../../Queries/queryFormatter'
import SessionStore from '../../State/SessionStore'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import { useLayoutStore } from '@2/layout/layout-store'
import { useGlobalCache } from '@2/cache'

export const Route = createFileRoute('/_private/invoices/')({
    beforeLoad: ({ params, search }) => {
        if (!canViewRoute(SessionStore.user, 'invoiceList', params))
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            const cache = useGlobalCache.getState()
            const report = cache.getItemById(
                'organisationReports',
                search.report
            )
            layoutStore.setDefaults({
                pageTitle: report?.name || 'Invoice Report',
                subMenu: 'invoices',
            })
        }
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async ({ report }) => {
    const reportId = report || SessionStore.organisation?.defaultInvoiceReportId
    const collections = [
        reportId
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
    ].filter(Boolean)
    return Promise.all(collections.map(fetchData))
}

function Wrapper() {
    const { report } = Route.useSearch()
    if (migratedToV2()) {
        return <InvoiceListPage id={report} />
    }
    return (
        <>
            <InvoiceListPageHeader id={report} />
            <PageBody>
                <InvoiceListPage id={report} />
            </PageBody>
        </>
    )
}
