import { useState } from 'react'
import { Settings, LayoutDashboard, ReceiptText, LineChart } from 'lucide-react'
import { useGlobalCache } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'
import { useNavigate } from '@tanstack/react-router'

export const ExpensesSubNav = () => {
    const navigate = useNavigate()
    const [expandedList, setExpandedList] = useState('reports')
    const queryCache = useGlobalCache((state) => state.queryCache)
    const expenseReports = queryCache('organisationReports').filter(
        (r) => r.type === 'expense'
    )

    const lists = [
        {
            id: 'reports',
            title: 'Expense Reports',
            icon: LineChart,
            rootRoute: '/expense-reports',
            items: (expenseReports || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/expense-reports?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Expenses</SubNavigationHeading>
            <SubNavigationBody>
                <SideNavButton
                    icon={ReceiptText}
                    label="Track Expenses"
                    onClick={() => {
                        navigate({ to: '/track-expenses' })
                    }}
                />
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        items={list.items}
                        isExpanded={expandedList === list.id}
                        icon={list.icon}
                        rootRoute={list.rootRoute}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
            </SubNavigationBody>
            {/* <SubNavigationFooter>
                <NavButton
                    size="sm"
                    isExpanded={true}
                    icon={LayoutDashboard}
                    label="Project Templates"
                    onClick={() => null}
                />
                <NavButton
                    size="sm"
                    isExpanded={true}
                    icon={Settings}
                    label="Project Settings"
                    onClick={() => null}
                />
            </SubNavigationFooter> */}
        </SubNavigationSidebar>
    )
}
