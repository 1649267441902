import * as React from 'react'

import {
    type DataTableConfig,
} from '@/version2/config/data-table'

type FeatureFlagValue = DataTableConfig['featureFlags'][number]['value']

interface ProjectReportTableContextProps {
    featureFlags: FeatureFlagValue[]
    setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlagValue[]>>
}

const ProjectReportTableContext =
    React.createContext<ProjectReportTableContextProps>({
        featureFlags: [],
        setFeatureFlags: () => {},
    })

export function useProjectReportTable() {
    const context = React.useContext(ProjectReportTableContext)
    if (!context) {
        throw new Error(
            'useProjectReportTable must be used within a ProjectReportTableProvider'
        )
    }
    return context
}

export function ProjectReportTableProvider({
    children,
}: React.PropsWithChildren) {
    const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagValue[]>([
        'advancedFilter',
    ])

    return (
        <ProjectReportTableContext.Provider
            value={{
                featureFlags,
                setFeatureFlags,
            }}
        >
            {/* <div className="w-full overflow-x-auto mb-2">
                <ToggleGroup
                    type="multiple"
                    variant="outline"
                    size="sm"
                    value={featureFlags}
                    onValueChange={(value: FeatureFlagValue[]) => {
                        console.log(value)
                        setFeatureFlags(value)
                    }}
                    className="w-fit"
                >
                    {dataTableConfig.featureFlags.map((flag) => (
                        <Tooltip key={flag.value} delayDuration={250}>
                            <ToggleGroupItem
                                value={flag.value}
                                className="whitespace-nowrap px-3 text-xs"
                                asChild
                            >
                                <TooltipTrigger>
                                    <flag.icon
                                        className="mr-2 size-3.5 shrink-0"
                                        aria-hidden="true"
                                    />
                                    {flag.label}
                                </TooltipTrigger>
                            </ToggleGroupItem>
                            <TooltipContent
                                align="start"
                                side="bottom"
                                sideOffset={6}
                                className="flex max-w-60 flex-col space-y-1.5 border bg-background py-2 font-semibold text-foreground"
                            >
                                <div>{flag.tooltipTitle}</div>
                                <div className="text-xs text-muted-foreground">
                                    {flag.tooltipDescription}
                                </div>
                            </TooltipContent>
                        </Tooltip>
                    ))}
                </ToggleGroup>
            </div> */}
            {children}
        </ProjectReportTableContext.Provider>
    )
}
