import { useState } from 'react'
import { Settings, LayoutDashboard } from 'lucide-react'
import { useGlobalCache } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'
const getProjectTitle = (project) =>
    project.jobNumber ? `${project.jobNumber}: ${project.name}` : project.name

export const SuppliersSubNav = () => {
    const [expandedList, setExpandedList] = useState('reports')
    const queryCache = useGlobalCache((state) => state.queryCache)

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Suppliers</SubNavigationHeading>
            <SubNavigationBody>
                TBD
                {/* {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        items={list.items}
                        isExpanded={expandedList === list.id}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))} */}
            </SubNavigationBody>
            {/* <SubNavigationFooter>
                <SideNavButton
                    isExpanded={true}
                    icon={LayoutDashboard}
                    label="Project Templates"
                    onClick={() => null}
                />
                <SideNavButton
                    icon={Settings}
                    label="Project Settings"
                    onClick={() => null}
                />
            </SubNavigationFooter> */}
        </SubNavigationSidebar>
    )
}
