'use client'

import * as React from 'react'
import { TimePickerInput } from './time-picker-input'
import { TimePeriodSelect } from './time-period-select'
import { getPeriod, Period } from './time-picker-utils'
import { cn } from '@/lib/utils'

interface TimePickerProps {
    value: Date | undefined
    onChange: (date: Date | undefined) => void
    disabled?: boolean | undefined
    className?: string | undefined
}

export function TimePicker({
    value,
    onChange,
    disabled,
    className,
}: TimePickerProps) {
    disabled ??= false
    const [period, setPeriod] = React.useState<Period>(getPeriod(value))

    const minuteRef = React.useRef<HTMLInputElement>(null)
    const hourRef = React.useRef<HTMLInputElement>(null)
    const periodRef = React.useRef<HTMLButtonElement>(null)

    return (
        <div className={cn('flex items-end gap-2', className)}>
            <div className="grid gap-1 text-center">
                <TimePickerInput
                    picker="12hours"
                    period={period}
                    date={value}
                    setDate={onChange}
                    disabled={disabled}
                    ref={hourRef}
                    onRightFocus={() => minuteRef.current?.focus()}
                />
            </div>
            <div className="grid gap-1 text-center">
                <TimePickerInput
                    picker="minutes"
                    id="minutes12"
                    date={value}
                    setDate={onChange}
                    disabled={disabled}
                    ref={minuteRef}
                    onLeftFocus={() => hourRef.current?.focus()}
                    onRightFocus={() => periodRef.current?.focus()}
                />
            </div>
            <div className="grid gap-1 text-center">
                <TimePeriodSelect
                    period={period}
                    setPeriod={setPeriod}
                    date={value}
                    setDate={onChange}
                    disabled={disabled}
                    ref={periodRef}
                    onLeftFocus={() => minuteRef.current?.focus()}
                />
            </div>
        </div>
    )
}
