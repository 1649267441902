import { useState } from 'react'
import {
    Settings,
    LayoutDashboard,
    Calendar,
    SquareKanban,
    HousePlug,
    Boxes,
    LineChart,
    Users,
} from 'lucide-react'
import { useGlobalCache } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'
import { useNavigate } from '@tanstack/react-router'

export const OrgSubNav = () => {
    const navigate = useNavigate()
    const [expandedList, setExpandedList] = useState('revenueForecast')
    const queryCache = useGlobalCache((state) => state.queryCache)
    const revenueForecastReports = queryCache('revenueForecastReports')
    const resourceScheduleReports = queryCache('resourceScheduleReports')
    const lists = [
        {
            id: 'revenueForecast',
            title: 'Revenue Forecast',
            icon: LineChart,
            rootRoute: '/revenue-forecast',
            items: (revenueForecastReports || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/revenue-forecast?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'resourceSchedule',
            title: 'Resource Schedule',
            icon: Users,
            rootRoute: '/resource-schedule',
            items: (resourceScheduleReports || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/resource-schedule?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Organisation</SubNavigationHeading>
            <SubNavigationBody>
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        items={list.items}
                        isExpanded={expandedList === list.id}
                        icon={list.icon}
                        rootRoute={list.rootRoute}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
                <SideNavButton
                    icon={SquareKanban}
                    label="Weekly Planning"
                    onClick={() => {
                        navigate({ to: '/weekly-planning' })
                    }}
                />
            </SubNavigationBody>
            <SubNavigationFooter>
                <SideNavButton
                    icon={Calendar}
                    label="Holidays"
                    onClick={() => {
                        navigate({ to: '/organisation-holidays' })
                    }}
                />
                <SideNavButton
                    icon={HousePlug}
                    label="Operational Expenses"
                    onClick={() => {
                        navigate({ to: '/overhead-expenses' })
                    }}
                />
                <SideNavButton
                    icon={Boxes}
                    label="Cost Centres"
                    onClick={() => {
                        navigate({ to: '/cost-centres' })
                    }}
                />
            </SubNavigationFooter>
        </SubNavigationSidebar>
    )
}
