import { observer } from 'mobx-react'
import React, { Suspense, useEffect } from 'react'
import { useQueries, useQueryErrorResetBoundary } from '@tanstack/react-query'
import fetchData from '../../Queries/fetchData'
import { ErrorBoundary } from 'react-error-boundary'
import MiniLoadingSpinner from '../../Components/MiniLoadingSpinner'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { makeRequest } from '../../Queries/makeRequest'
import { isArray } from 'underscore'

export default observer(({ queryIds, children, loading }) => {
    const { reset } = useQueryErrorResetBoundary()
    // if (queryResults.some((qr) => qr.isError)) {
    //     queryResults.forEach((qr) => {
    //         if (qr.isError) console.error(qr.error)
    //     })
    //     return <button onClick={reset}>Try again</button>
    // } else if (queryResults.some((qr) => qr.fetchStatus === 'fetching')) {
    //     return loading || 'loading...'
    // } else {
    //     return children
    // }
    return (
        <ErrorBoundary
            onReset={reset}
            fallbackRender={({ error, resetErrorBoundary }) => {
                Sentry.captureException(error)
                return (
                    <div>
                        There was an error!
                        <button
                            className="btn btn-default"
                            style={{
                                padding: '0.25em 0.5em',
                                marginLeft: '0.5em',
                                fontSize: '0.9em',
                            }}
                            onClick={() => resetErrorBoundary()}
                        >
                            Try again
                        </button>
                    </div>
                )
            }}
        >
            <Suspense fallback={loading || <MiniLoadingSpinner />}>
                <RenderItems key={queryIds} queryIds={queryIds}>
                    {children}
                </RenderItems>
            </Suspense>
        </ErrorBoundary>
    )
})

const RenderItems = observer(({ queryIds, children }) => {
    const queryResults = useQueries({
        queries: queryIds.map((qId) => {
            return {
                queryKey: isArray(qId) ? qId : [qId?.id || qId],
                queryFn: qId.collection
                    ? async () => await fetchData(qId)
                    : async () => await makeRequest(qId),
                staleTime: qId.staleTime || 0,
                cacheTime: qId.cacheTime || 0,
                retry: 3,
            }
        }),
    })
    return <>{children}</>
})
