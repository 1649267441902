import { durationToUnit } from '@ryki/datemath'
import { statusPriorities } from '../Utils/statusPriority'

export default {
    boolean: (value) => value ?? false,
    text: (value) => (value ? String(value).toLowerCase() : ''),
    number: (value) => value ?? null,
    currency: (value) => value ?? null,
    percent: (value) => value ?? null,
    durationMinutes: (value) => value ?? null,
    date: (value) =>
        value instanceof Date && !isNaN(value) ? value.getTime() : null,
    time: (value) =>
        value instanceof Date && !isNaN(value) ? value.getTime() : null,
    duration: (value) =>
        value?.value && value?.unit
            ? durationToUnit(value, 'milliseconds')
            : null,
    progress: (value) =>
        value?.denominator ? value.numerator / value.denominator : null,
    progressBar: (value) =>
        value?.denominator ? value.numerator / value.denominator : null,
    project: (value) => value?.id ?? '',
    phase: (value) => value?.id ?? '',
    costCentre: (value) => value?.id ?? '',
    contact: (value) => value?.id ?? '',
    staff: (value) => value?.id ?? '',
    staffMember: (value) => value?.id ?? '',
    role: (value) => value?.id ?? '',
    status: (value) => statusPriorities[value] ?? null,
}
