import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import RenderOnQueries from '../Layout/RenderOnQueries'
import InvoiceDescriptionTemplateCollection from '../../State/Collections/InvoiceDescriptionTemplateCollection'
import DataStore from '../../State/DataStore'
import { InvoiceCodeInfo } from '../../invoices/invoiceCodeInfo'
import { Selector } from '../../Components/Selector'
import HeaderButton from '../../Components/ui/HeaderButton'

export default observer(
    ({ modalId, defaultSelectedTemplate, defaultState }) => {
        const templates =
            InvoiceDescriptionTemplateCollection.descriptionTemplates.filter(
                (dt) => !dt.deletedAt
            )
        const [selectedTemplate, setSelectedTemplate] = useState(
            defaultSelectedTemplate
        )
        const [state, setState] = useState(defaultState || 'display')
        const [name, setName] = useState(selectedTemplate?.name)
        const [description, setDescription] = useState(
            selectedTemplate?.description
        )
        console.log(state)
        return (
            <Modal
                modalId={modalId}
                heading="Invoice Templates"
                onSave={() => {
                    if (state === 'edit') {
                        selectedTemplate.update({
                            name,
                            description,
                        })
                        DataStore.saveModel(selectedTemplate)
                    }
                }}
            >
                <RenderOnQueries
                    queryIds={[
                        {
                            collection: 'invoiceDescriptionTemplates',
                            fields: ['name', 'description'],
                        },
                    ]}
                >
                    <div className="description-template-modal">
                        <div className="p-6">
                            <div className="flex w-full gap-2">
                                {state === 'display' && (
                                    <Selector
                                        // style={{marginLeft: '0.5em'}}
                                        className="flex-auto"
                                        value={selectedTemplate}
                                        onChange={(template) => {
                                            setSelectedTemplate(template)
                                            setName(template.name)
                                            setDescription(template.description)
                                        }}
                                        options={templates.map((t) => ({
                                            label: `${t.name}`,
                                            value: t,
                                        }))}
                                        placeholder={'Select Template...'}
                                        variant="secondary"
                                    />
                                )}
                                {state === 'edit' && (
                                    <input
                                        style={{
                                            padding: '0.5em 1em',
                                            fontSize: '1em',
                                            borderRadius: '0.5em',
                                            border: '1px solid #ccc',
                                        }}
                                        className="flex-auto"
                                        value={name || ''}
                                        onChange={(event) =>
                                            setName(event.target.value)
                                        }
                                    />
                                )}
                                {selectedTemplate?.id && state === 'edit' && (
                                    <button
                                        className="flex-[0_0_0%] btn btn-danger"
                                        style={{ margin: '0 0 0 0.5em' }}
                                        onClick={(e) => {
                                            selectedTemplate.update({
                                                deletedAt: new Date(),
                                            })
                                            setSelectedTemplate(null)
                                            setState('display')
                                        }}
                                    >
                                        {'Delete'}
                                    </button>
                                )}
                                {selectedTemplate && state === 'display' && (
                                    <button
                                        className="flex-[0_0_0%] btn btn-primary"
                                        style={{ margin: '0 0 0 0.5em' }}
                                        onClick={(e) => {
                                            setState('edit')
                                            setName(selectedTemplate.name)
                                            setDescription(
                                                selectedTemplate.description
                                            )
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                                {state === 'edit' && (
                                    <button
                                        className="flex-[0_0_0%] btn btn-success"
                                        style={{ margin: '0 0 0 0.5em' }}
                                        onClick={(e) => {
                                            selectedTemplate.update({
                                                name,
                                                description,
                                            })
                                            DataStore.saveModel(
                                                selectedTemplate
                                            )
                                            setState('display')
                                        }}
                                    >
                                        {'Save'}
                                    </button>
                                )}
                                {state === 'display' && (
                                    <HeaderButton
                                        label="+ New"
                                        onClick={(e) => {
                                            const newTemplate =
                                                InvoiceDescriptionTemplateCollection.add(
                                                    {
                                                        name: 'New Template',
                                                        description:
                                                            'Template description...',
                                                    },
                                                    { trackUpdates: true }
                                                )
                                            setSelectedTemplate(newTemplate)
                                            setName(newTemplate.name)
                                            setDescription(
                                                newTemplate.description
                                            )
                                            setState('edit')
                                            DataStore.saveModel(newTemplate)
                                        }}
                                        variant="secondary"
                                        className="flex-[0_0_0%]"
                                    />
                                )}
                                {state === 'edit' && (
                                    <button
                                        className="flex-[0_0_0%] btn btn-default"
                                        style={{ margin: '0 0 0 0.5em' }}
                                        onClick={(e) => setState('display')}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </div>
                            {selectedTemplate && state === 'display' && (
                                <p
                                    style={{
                                        width: '100%',
                                        marginTop: '1em',
                                        padding: '1em',
                                    }}
                                >
                                    {`"  ${selectedTemplate.description}  "`}
                                </p>
                            )}
                            {selectedTemplate && state === 'edit' && (
                                <div style={{ position: 'relative' }}>
                                    <textarea
                                        rows={8}
                                        style={{
                                            width: '100%',
                                            marginTop: '1em',
                                            padding: '1em',
                                            borderRadius: '0.5em',
                                        }}
                                        value={description}
                                        disabled={state === 'display'}
                                        onChange={(event) =>
                                            setDescription(event.target.value)
                                        }
                                    />
                                    <InvoiceCodeInfo
                                        style={{
                                            position: 'absolute',
                                            top: '1.4em',
                                            right: 0,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </RenderOnQueries>
            </Modal>
        )
    }
)
