import { createFileRoute } from '@tanstack/react-router'
import TrackExpensesPageHeader from '../../Pages/TrackExpensesPage/TrackExpensesPageHeader'
import TrackExpensesPage from '../../Pages/TrackExpensesPage/TrackExpensesPage'
import PageBody from '../../Pages/Layout/PageBody'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { useLayoutStore } from '@2/layout/layout-store'

export const Route = createFileRoute('/_private/track-expenses')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'trackExpenses', params))
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            layoutStore.setDefaults({
                pageTitle: 'Track Expenses',
                subMenu: 'expenses',
            })
        }
    },
    loaderDeps: ({ search: { date, staffId } }) => ({
        date,
        staffId,
    }),
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
})

function Wrapper() {
    if (migratedToV2()) {
        return (
            <TrackExpensesPage {...Route.useParams()} {...Route.useSearch()} />
        )
    }
    return (
        <>
            <TrackExpensesPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <TrackExpensesPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
        </>
    )
}
