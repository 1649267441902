import { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Column, Row, Table } from '@tanstack/react-table'
import * as cuid from 'cuid'
import { useProjectReportStore } from './project-report-store'
import * as React from 'react'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
import download from 'downloadjs'
import Papa from 'papaparse'
import Formatter from '@/Components/Formatter'
import { trpc } from '@/system/trpc'
import { toast } from '@vendor/sonner'
import SessionStore from '@/State/SessionStore'
import { useNavigate } from '@tanstack/react-router'
import { ExportColumn, Fraction, OrganisationReport } from '@/version2/types'
import { DataTableActions } from '@/version2/components/data-table/advanced/data-table-action'
import { useGlobalCache } from '@/version2/cache'
import { useLayoutStore } from '@/version2/layout/layout-store'

interface ProjectReportActionsProps<TData>
    extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
    exportColumns: ExportColumn[]
}

export function ProjectReportActions<TData>({
    table,
    exportColumns,
}: ProjectReportActionsProps<TData>) {
    const navigate = useNavigate()
    const [dialogContent, setDialogContent] = useState<string | null>(null)
    const [nameReport, setNameReport] = useState<string | null>(null)
    const { updateCache, deleteItem, cache } = useGlobalCache((state) => state)
    const { setPageTitle } = useLayoutStore((state) => state)

    const { organisationReport, setOrganisationReport } = useProjectReportStore(
        (state) => state
    )
    const checkDisableButton =
        !organisationReport?.id &&
        !SessionStore.organisation?.defaultProjectReportId

    const handleOpenDialog = (content: string) => {
        setNameReport(null)
        setDialogContent(content)
    }

    const csvFormatter = (
        data: Row<TData>,
        column: Column<TData, unknown>
    ): string => {
        const value = data.getValue(column?.id)
        switch (column.columnDef.meta?.type) {
            case 'progress':
            case 'progressBar':
                const { numerator, denominator } = value as Fraction
                if (numerator || denominator) {
                    return `${Formatter.progress(
                        numerator || 0
                    )} / ${Formatter.progress(denominator || 0)}`
                } else {
                    return ''
                }
            case 'date':
                return data
                    ? FormatDate(new Date(value as string), {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      })
                    : ''
            default:
                return Formatter[column.columnDef.meta?.type](value) ?? data
        }
    }
    const columns = useProjectReportStore(
        (state) => state.organisationReport?.columns
    )

    const handleExportToCSV = (type: string) => {
        const results: Array<string>[] = []
        // Add headers
        const headers = table
            .getAllColumns()
            .filter((item) => columns?.includes(item.id))
        const firstRow: Array<string> = []
        if (type !== 'project') {
            firstRow.push('Project')
        }
        headers.forEach((header) => firstRow.push(header.columnDef.meta?.title))
        results.push(firstRow)
        // Add data
        table.getRowModel().rows.forEach((item: Row<TData>) => {
            if (type == 'project') {
                const row: string[] = []
                headers.forEach((header) => {
                    row.push(csvFormatter(item, header))
                })
                results.push(row)
            } else {
                const phases = item?.subRows
                if (phases.length) {
                    phases.forEach((phase) => {
                        const dataPhase = [item.getValue('name').toString()]
                        headers.forEach((header) => {
                            dataPhase.push(csvFormatter(phase, header))
                        })
                        results.push(dataPhase)
                    })
                }
            }
        })

        download(Papa.unparse(results), `project.csv`, 'text/csv')
    }

    const handleSaveReport = async (payload: Partial<OrganisationReport>) => {
        return toast.promise(trpc.projectReport.saveReport.mutate(payload), {
            loading: 'Saving changes...',
            success: (data) => {
                setNameReport(null)
                setOrganisationReport({
                    ...organisationReport,
                    ...data,
                })
                return 'All saved'
            },
            error: (errorToast) => {
                console.log(errorToast)
                return (
                    <div className="flex items-center justify-between w-full">
                        <span>Report has not been updated</span>
                        <Button
                            onClick={() => {
                                toast.dismiss()
                                handleSaveReport(payload)
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                )
            },
        })
    }

    const handleSetDefault = async () => {
        toast.promise(
            trpc.projectReport.setDefaultReport.mutate({
                id: organisationReport.id,
            }),
            {
                loading: 'Saving changes...',
                success: 'All saved',
                error: (errorToast) => {
                    return (
                        <div className="flex items-center justify-between w-full">
                            <span>Report has not been set default</span>
                            <Button
                                onClick={() => {
                                    toast.dismiss()
                                    handleSetDefault()
                                }}
                            >
                                Retry
                            </Button>
                        </div>
                    )
                },
            }
        )
    }

    const handleDialogSubmit = async (dialogContent: string) => {
        switch (dialogContent) {
            case 'Save as':
                const newId = cuid()
                const payloadSaveAs = {
                    id: newId,
                    name: nameReport,
                    organisationId: organisationReport.organisationId,
                    filters: organisationReport.filters,
                    type: 'project',
                    columns: organisationReport.columns,
                    options: organisationReport.options,
                }
                await handleSaveReport(payloadSaveAs)
                updateCache('organisationReports', [
                    ...cache.organisationReports,
                    { id: newId, name: nameReport, type: 'project' },
                ])
                break
            case 'Save':
                const payloadSave = {
                    id: organisationReport.id,
                    organisationId: organisationReport.organisationId,
                    filters: organisationReport.filters,
                    columns: organisationReport.columns,
                    options: organisationReport.options,
                }
                await handleSaveReport(payloadSave)
                break
            case 'Rename Report':
                if (nameReport !== organisationReport.name) {
                    const payloadRename = {
                        id: organisationReport.id,
                        organisationId: organisationReport.organisationId,
                        name: nameReport,
                    }
                    await handleSaveReport(payloadRename)
                    updateCache('organisationReports', [
                        ...cache.organisationReports.filter(
                            (item) => item.id !== organisationReport.id
                        ),
                        {
                            id: organisationReport.id,
                            name: nameReport,
                            type: 'project',
                        },
                    ])
                    setPageTitle(nameReport)
                }
                break
            case 'Delete Report':
                const payloadDelete = {
                    id: organisationReport.id,
                    organisationId: organisationReport.organisationId,
                    deletedAt: new Date(),
                }
                await handleSaveReport(payloadDelete)
                deleteItem('organisationReports', organisationReport.id)
                break
            default:
                break
        }
        setDialogContent(null)
    }
    return (
        <>
            <DataTableActions
                handleExportToCSV={handleExportToCSV}
                handleDialogSubmit={handleDialogSubmit}
                handleOpenDialog={handleOpenDialog}
                handleSetDefault={handleSetDefault}
                dialogContent={dialogContent}
                setDialogContent={setDialogContent}
                setNameReport={setNameReport}
                nameReport={nameReport}
                organisationReport={organisationReport}
                checkDisableButton={checkDisableButton}
                exportColumns={exportColumns}
            />
        </>
    )
}
