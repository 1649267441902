import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { OrganisationReport } from '@/version2/types'

interface TimeReport {
    budget: number
    chargeOut: number
    cost: number
    date: string | null
    hoursBudget: number
    key: string
    label: string
    notes: string | null
    numMinutes: number
    phaseId: string | null
    project: string
    projectId: string
    projectPhase: string | null
    staffId: string | null
    staffMember: string
    task: string
    taskId: string | null
    timeEntryId: string | null
}

interface TimeReportData extends TimeReport {
    children?: TimeReport[]
}

interface TimeReportState {
    reportData: TimeReportData[]
    organisationReport: OrganisationReport
    isLoadingReportData: boolean
    isHideColumn: boolean
    columns: string[]
    setReportData: (newReportData: TimeReportData[] | []) => void
    setOrganisationReport: (newProjectReport: OrganisationReport) => void
    setIsLoadingReportData: (isLoading: boolean) => void
    setIsHideColumn: (isHideColumn: boolean) => void
    setColumns: (newColumns: string[]) => void
}

export const useTimeReportStore = create<TimeReportState>()(
    devtools(
        immer((set, get) => ({
            reportData: null,
            organisationReport: null,
            isLoadingReportData: false,
            isHideColumn: false,
            columns: null,
            listProjects: [],
            listPhases: [],
            listTasks: [],
            setOrganisationReport: (
                newOrganisationReport: OrganisationReport
            ) => set({ organisationReport: newOrganisationReport }),
            setReportData: (newReportData: TimeReportData[] | []) =>
                set({ reportData: newReportData }),
            setIsLoadingReportData: (isLoading: boolean) =>
                set({ isLoadingReportData: isLoading }),
            setIsHideColumn: (isHide: boolean) => set({ isHideColumn: isHide }),
            setColumns: (newColumns: string[]) =>
                set((state) => {
                    if (state.organisationReport) {
                        state.organisationReport.columns = newColumns
                    }
                }),
        }))
    )
)
