import { format } from 'date-fns'
import pluralize from 'pluralize'
import ProgressBar2 from './Widgets/ProgresBar2'
import React from 'react'
import Inputs from './Inputs'

const PlainTextCellComponent = ({
    formattedValue,
    onClick,
    onFocus,
    onBlur,
    style,
}) => {
    return (
        <div
            style={{
                width: '100%',
            }}
            {...{ onClick, onFocus, onBlur, style }}
        >
            {formattedValue || <span>&nbsp;</span>}
        </div>
    )
}

export default {
    boolean: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        style,
    }) => {
        return editable ? (
            Inputs.checkbox({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
                style,
                disabled: !editable,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        )
    },
    text: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        style,
    }) =>
        editable ? (
            Inputs.text({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
                style,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    number: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        style,
    }) =>
        editable ? (
            Inputs.number({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
                style,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                    style: {
                        color: value < 0 ? 'red' : 'inherit',
                    },
                }}
            />
        ),
    currency: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        style,
    }) => {
        return editable ? (
            Inputs.currency({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
                style,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                    style: {
                        color: value < 0 ? 'red' : 'inherit',
                    },
                }}
            />
        )
    },
    percent: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.percent({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                    style: {
                        color: value < 0 ? 'red' : 'inherit',
                    },
                }}
            />
        ),
    date: ({
        value,
        formattedvalue,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.date({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    time: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        style,
    }) =>
        editable ? (
            Inputs.time({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
                style,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    duration: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.duration({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    progress: ({
        value,
        formattedValue,
        formatter,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return (
            <div>
                {`${
                    isFinite(value?.numerator)
                        ? formatter(value?.numerator)
                        : '-'
                } / ${
                    isFinite(value?.denominator)
                        ? formatter(value?.denominator)
                        : '-'
                }`}
            </div>
        )
    },
    progressBar: ({
        value,
        formattedValue,
        formatter,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return (
            <ProgressBar2
                width="100%"
                height="1.5em"
                numerator={value?.numerator}
                denominator={value?.denominator}
                formatNumber={formatter}
            />
        )
    },
    project: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.project({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    phase: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.phase({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    costCentre: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return editable ? (
            Inputs.costCentre({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        )
    },
    contact: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.contact({
                value,
                formattedValue,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    staff: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.staff({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    staffMember: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.staff({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    role: ({
        value,
        formattedvalue,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.role({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    button: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return value
            ? Inputs.button({
                  value,
                  editable,
                  onChange,
                  onClick,
                  onFocus,
                  onBlur,
                  validator,
                  group,
                  key,
                  disabled: !editable,
              })
            : null
    },
    checkbox: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return Inputs.checkbox({
            value,
            editable,
            onChange,
            onClick,
            onFocus,
            onBlur,
            validator,
            group,
            key,
            disabled: !editable,
        })
    },
    textarea: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return editable ? (
            Inputs.textarea({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <div>{value}</div>
        )
    },
    status: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return editable ? (
            Inputs.status({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        )
    },
    staffOrRole: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) => {
        return Inputs.staffOrRole({
            value,
            editable,
            onChange,
            onClick,
            onFocus,
            onBlur,
            validator,
            group,
            key,
        })
    },
    task: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.task({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
    durationMinutes: ({
        value,
        formattedValue,
        editable = false,
        onChange,
        onClick,
        onFocus,
        onBlur,
        validator,
        group,
        key,
        stores,
    }) =>
        editable ? (
            Inputs.durationMinutes({
                value,
                editable,
                onChange,
                onClick,
                onFocus,
                onBlur,
                validator,
                group,
                key,
            })
        ) : (
            <PlainTextCellComponent
                {...{
                    formattedValue,
                    onClick,
                    onFocus,
                    onBlur,
                }}
            />
        ),
}

export const roundNumberToDecimalPlaces = (
    number,
    decimals = 2,
    multiplyer = 1
) => {
    return (
        Math.round(
            (parseFloat(number) || 0) * multiplyer * Math.pow(10, decimals)
        ) / Math.pow(10, decimals)
    )
}
