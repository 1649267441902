import { create } from 'zustand'

interface Tab {
    id: string
    label: string
}

interface LayoutState {
    pageTitle: string
    subMenu: string | null
    tabs: Tab[]
    showSidebar: boolean
    setPageTitle: (title: string) => void
    setSubMenu: (menu: string | null) => void
    setTabs: (tabs: Tab[]) => void
    setShowSidebar: (show: boolean) => void
}

export const useLayoutStore = create<LayoutState>((set) => ({
    pageTitle: '',
    subMenu: null,
    tabs: [],
    showSidebar: true,
    setPageTitle: (title) => set({ pageTitle: title }),
    setSubMenu: (menu) => set({ subMenu: menu }),
    setTabs: (tabs) => set({ tabs }),
    setShowSidebar: (show) => set({ showSidebar: show }),
    setDefaults: ({ pageTitle, subMenu, tabs }) =>
        set({ pageTitle, subMenu, tabs }),
}))
