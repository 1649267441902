import {
    ArrowDownIcon,
    ArrowUpIcon,
    CaretSortIcon,
    EyeNoneIcon,
} from '@radix-ui/react-icons'
import { type Column } from '@tanstack/react-table'

import { cn } from '@2/lib/utils'
import { Button } from '@2/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@2/components/ui/dropdown-menu'
import { useProjectReportStore } from '@/version2/pages/project-report/project-report-store'

interface DataTableColumnHeaderProps<TData, TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<TData, TValue>
    title: string
    setIsAlertChangeColumns?: React.Dispatch<
        React.SetStateAction<boolean>
    > | null
}

export function DataTableColumnHeader<TData, TValue>({
    column,
    title,
    className,
    setIsAlertChangeColumns = null,
}: DataTableColumnHeaderProps<TData, TValue>) {
    if (!column.getCanSort() && !column.getCanHide()) {
        return <div className={cn(className)}>{title}</div>
    }

    const { organisationReport, setOrganisationReport, setIsHideColumn } =
        useProjectReportStore((state) => state)

    const handleHideColumn = () => {
        column.toggleVisibility(false)
        if (setIsAlertChangeColumns) {
            setIsAlertChangeColumns(true)
            setIsHideColumn(true)
            setOrganisationReport({
                ...organisationReport,
                columns: organisationReport.columns.filter(
                    (item) => item !== column.id
                ),
            })
        }
    }

    return (
        <div className={cn('flex items-center space-x-2', className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        aria-label={
                            column.getIsSorted() === 'desc'
                                ? 'Sorted descending. Click to sort ascending.'
                                : column.getIsSorted() === 'asc'
                                  ? 'Sorted ascending. Click to sort descending.'
                                  : 'Not sorted. Click to sort ascending.'
                        }
                        variant="ghost"
                        size="sm"
                        className="-ml-3 h-auto print:text-[0.65rem] flex max-w-full p-2 hover:bg-transparent focus:bg-transparent data-[state=open]:bg-accent"
                    >
                        <span className="flex-auto text-wrap text-left">
                            {title}
                        </span>
                        <span className="flex-none">
                            {column.getCanSort() &&
                            column.getIsSorted() === 'desc' ? (
                                <ArrowDownIcon
                                    className="ml-2 size-4 print:hidden"
                                    aria-hidden="true"
                                />
                            ) : column.getIsSorted() === 'asc' ? (
                                <ArrowUpIcon
                                    className="ml-2 size-4 print:hidden"
                                    aria-hidden="true"
                                />
                            ) : (
                                <CaretSortIcon
                                    className="ml-2 size-4 print:hidden"
                                    aria-hidden="true"
                                />
                            )}
                        </span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    {column.getCanSort() && (
                        <>
                            <DropdownMenuItem
                                aria-label="Sort ascending"
                                onClick={() => column.toggleSorting(false)}
                            >
                                <ArrowUpIcon
                                    className="mr-2 size-3.5 text-muted-foreground/70"
                                    aria-hidden="true"
                                />
                                Asc
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                aria-label="Sort descending"
                                onClick={() => column.toggleSorting(true)}
                            >
                                <ArrowDownIcon
                                    className="mr-2 size-3.5 text-muted-foreground/70"
                                    aria-hidden="true"
                                />
                                Desc
                            </DropdownMenuItem>
                        </>
                    )}
                    {column.getCanSort() && column.getCanHide() && (
                        <DropdownMenuSeparator />
                    )}
                    {column.getCanHide() && (
                        <DropdownMenuItem
                            aria-label="Hide column"
                            onClick={handleHideColumn}
                        >
                            <EyeNoneIcon
                                className="mr-2 size-3.5 text-muted-foreground/70"
                                aria-hidden="true"
                            />
                            Hide
                        </DropdownMenuItem>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
