import { createFileRoute } from '@tanstack/react-router'
import RevenueForecastPageHeader from '../../Pages/RevenueForecastPage/RevenueForecastPageHeader'
import RevenueForecastPage from '../../Pages/RevenueForecastPage/RevenueForecastPage'
import PageBody from '../../Pages/Layout/PageBody'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import RevenueForecastSidebar from '../../Pages/RevenueForecastPage/RevenueForecastSidebar'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import SessionStore from '../../State/SessionStore'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import { qf } from '../../Queries/queryFormatter'
import { useLayoutStore } from '@2/layout/layout-store'
import { useGlobalCache } from '@2/cache'

export const Route = createFileRoute('/_private/revenue-forecast')({
    beforeLoad: ({ params, search }) => {
        if (!canViewRoute(SessionStore.user, 'revenueForecast', params))
            throw new Error('Permission Denied')
        if (usingNewLogin()) {
            const layoutStore = useLayoutStore.getState()
            const cache = useGlobalCache.getState()
            const report = cache.getItemById(
                'revenueForecastReports',
                search.report
            )
            layoutStore.setDefaults({
                pageTitle: report?.name || 'Revenue Forecast',
                subMenu: 'organisation',
            })
        }
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
})

const loader = async ({ report }) => {
    const reportId =
        report || SessionStore.organisation?.defaultRevenueForecastReportId
    const collections = [
        reportId
            ? {
                  collection: 'revenueForecastReports',
                  fields: ['name', 'filters'],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'revenueForecastReports',
            fields: ['name'],
        },
        {
            collection: 'overheadExpenses',
            fields: ['name'],
        },
    ].filter(Boolean)
    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return <RevenueForecastPage />
    }
    return (
        <>
            <RevenueForecastPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <RevenueForecastPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
            <PageSidebar>
                <RevenueForecastSidebar
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageSidebar>
        </>
    )
}
